/* @ngInject */
export default function AbstractDiscussionLectureComponentModel(
  _,
  $translate,
  LectureComponentBaseModel,
  DiscussionsManager,
  PostModel,
  config,
) {
  // NOTE: Despite being named `abstract`, this class _is_ instantiated when making a new Discussion
  // component of either type
  class AbstractDiscussionLectureComponent extends LectureComponentBaseModel {
    constructor(attributes, isSample = false, useDefaults = false) {
      const defaults = {
        type: 'AbstractDiscussionLectureComponent',
        editFormTemplateUrl: 'lecture_pages/templates/components/discussion-modal.html',
        editFormController: 'DiscussionFormModalCtrl',
        realComponent: null,
        shouldBulkUpdateDiscussionStarter: undefined,
        scrollToOffset: undefined,
      };

      defaults.topic = defaults.post;

      super(_.extend({}, defaults, attributes));

      this.__preprocess();

      if (useDefaults) {
        this.createDraft();
      }
    }

    setDiscussion(component) {
      this.realComponent = component;
    }

    setUpdatedDiscussionStarter(shouldBulkUpdateDiscussionStarter) {
      this.shouldBulkUpdateDiscussionStarter = shouldBulkUpdateDiscussionStarter;
    }

    setInitialScrollTopDiscussionStarter(scrollToOffset) {
      this.scrollToOffset = scrollToOffset;
    }

    __preprocess() {
      if (this.topic) {
        this.post = PostModel.normalizePostData(
          {
            // If this already have a post, overriding with it to preserve
            // already loaded states. The updatedPost is passed from
            // angular-lecture-component to pass the latest data from redux
            ...this.topic,
            ...this.post,
            ...this.updatedPost,
          },
          { catalogId: this.catalogId, owner: this.topic.forum },
        );

        // Topic needed to preserve format so normalizr shemas can be used for loadLecturePage and updateLectureComponent
        // delete this.topic;
      }
    }

    /* Student Functions */
    getStatus() {
      return this.post.progress;
    }

    isCompleted() {
      return this.post.progress === 'completed';
    }

    /* Admin Functions */
    createDraft() {
      this.postDraft = _.clone(this.post);
    }

    saveDraft() {
      const updateAvatar = this.post.user?.id !== this.postDraft.starterId;
      _.extend(this.post, this.postDraft);
      _.extend(this.post, { post: true, updateAvatar });
    }

    save(...args) {
      if (!this.post.title) {
        this.post.title = $translate.instant('LECTURE_PAGES.COMPONENTS.UNTITLED');
      }

      return super.save(...args);
    }

    isTodo() {
      return this.post.isTodo;
    }

    toggleToDo() {
      this.post.isTodo = !this.post.isTodo;
      this.save();
    }

    isRequiredForCompletion() {
      return this.post.isRequiredForCompletion;
    }
  }

  AbstractDiscussionLectureComponent.iconClass = 'icon-conversations';
  AbstractDiscussionLectureComponent.showModalBeforeCreate = true;
  AbstractDiscussionLectureComponent.descriptionKey = 'LECTURE_PAGES.COMPONENTS.DISCUSSION.DESCRIPTION';
  AbstractDiscussionLectureComponent.pendoTagName = config.pendo.lectureEdit.discussion;

  return AbstractDiscussionLectureComponent;
}
