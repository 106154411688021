import { MANUAL_ADD_MODES, getMenteeName } from '../../user_management/controllers/manual-add-user-modal-controller';

export default {
  bindings: {
    class: '@?',
    onUserAdded: '<?',
    displayAvatar: '<?',
    mode: '<?',
    mentor: '<',
  },
  controllerAs: 'vm',
  templateUrl: 'lecture_pages/templates/nv-manual-add-user.html',
  controller: function ctrl(
    AlertMessages,
    ConfirmationOverlays,
    CourseRolesManager,
    CurrentCourseManager,
    CurrentPermissionsManager,
    CurrentUserManager,
    InstitutionsManager,
    UserManagementResources,
    _,
    config,
    validationConstants,
  ) {
'ngInject';
    const vm = this;

    vm.config = config;
    vm.TABLE_PAGE_SIZE = 30;
    vm.allowsSsoWithoutEmail = InstitutionsManager.institution.allowsSsoWithoutEmail;
    vm.isSearchAvailable = CurrentPermissionsManager.isConfigAndRegistrationRole() || CurrentPermissionsManager.isInstructor();
    vm.journeyHasAutoEnroll = CurrentCourseManager.course?.autoEnrollment;

    vm.doSearch = function (query) {
      if (!query || !query.length) {
        return;
      }

      vm.searchQuery = query;
      vm.isSearchSent = true;
      vm.isSearchLoading = true;
      vm.searchResults = [];

      if (vm.uiScrollAdapter) {
        vm.uiScrollAdapter.reload();
      }
    };

    /**
     * See 'Datasource' at https://github.com/angular-ui/ui-scroll
     * @param {number} index - this is 1-based
     * @param {number} count
     * @param {function(Array):void} success
     */
    vm.loadUsersInfiniteScroll = function (index, count, success) {
      const pageIndex = Math.ceil(index / vm.TABLE_PAGE_SIZE);
      let startIndex = index - 1;
      let endIndex = startIndex + count;
      if (startIndex < 0) {
        startIndex = 0;
      }
      if (endIndex < 0) {
        endIndex = 0;
      }

      if (startIndex < vm.searchResults.length) {
        success(vm.searchResults.slice(startIndex, endIndex));
      } else {
        vm.searchUsers(pageIndex, vm.searchQuery)
          .then((result) => {
            // Our endpoints packages the users list differently
            let users = result.users ? result.users : result;

            // Give each user an internal id for the results list. this is not their id on the backend or externalId
            users = _.map(users, (user, i) => {
              user.id = startIndex + i;
              return user;
            });

            vm.searchResults = vm.searchResults.concat(users);
            success(vm.searchResults.slice(startIndex, endIndex));
            vm.isSearchLoading = false;
          });
      }
    };

    vm.onSaveClicked = function (user) {
      switch (vm.mode) {
        case vm.MODES.COURSE_USER_ADD:
          vm.addUserToCourse(user);
          break;
        case vm.MODES.MENTEE_ADD:
          vm.assignMentee(user);
          break;
        default:
          break;
      }
    };

    // 'user' is optional - it's the user from the search results when a search result's 'Add' button is clicked. The user draft
    // is used instead when user is not provided
    vm.addUserToCourse = function (user) {
      vm.isSaving = true;

      if (user) {
        vm.savingUserId = user.id;
      } else {
        user = {
          firstName: vm.firstNameDraft,
          lastName: vm.lastNameDraft,
          email: vm.emailDraft,
        };
      }

      vm.selfEnroll = false;
      // We have checked email for the expression as we don't have userId at this time
      if (user.email === CurrentUserManager.user.email) {
        vm.selfEnroll = true;
      }

      const users = [user];

      vm.CurrentCourseManager.course.enrollWithRole(users, vm.selectedRole.id)
        .then((response) => {
          vm.isSaving = false;

          const { result } = response;
          const translationValues = _.extend(CurrentCourseManager.course.getAliases(), {
            addedCount: result.newlyEnrolled.length,
            rolesChangedCount: result.rolesChanged.length,
            existingCount: response.result.existing.length,
          });

          // Success or warning dialogs for whether the user was added, had their role changed,
          // or already existed in the course
          if (result.newlyEnrolled.length) {
            vm.AlertMessages.success('FORM.SUCCESS_BANG', 'USER_MANAGEMENT.CSV_UPLOAD_MODAL.USERS_ADDED', {}, translationValues);
          } else if (result.rolesChanged.length) {
            vm.AlertMessages.success('FORM.SUCCESS_BANG', 'USER_MANAGEMENT.CSV_UPLOAD_MODAL.USERS_ROLES_CHANGED', {}, translationValues);
          } else {
            AlertMessages.warning('', 'USER_MANAGEMENT.CSV_UPLOAD_MODAL.USERS_EXIST', {}, translationValues);
            return; // Exit early to avoid resetting drafts below
          }

          if (vm.onUserAdded) {
            vm.onUserAdded(result.newlyEnrolled);
          }

          if (vm.savingUserId) {
            vm.searchResults = _.filter(vm.searchResults, (userObject) => userObject.id === vm.savingUserId);
          }

          if (vm.selfEnroll) {
            CurrentUserManager.refreshUserCourse(vm.CurrentCourseManager.course.id);
          }

          vm.resetModal();
        }).catch((e) => {
          vm.AlertMessages.error('FORM.OOPS', 'USERS.REGISTRATION.ERRORS.GENERIC');
        });
    };

    vm.assignMentee = function (user) {
      vm.saving = true;
      vm.CurrentCourseManager.course.assignMentee(vm.mentor.id, user.email).then((response) => {
        vm.isSaving = false;

        AlertMessages.success('FORM.SUCCESS_BANG', 'USER_MANAGEMENT.ASSIGNED_MENTEE_TO', {}, {
          mentorAlias: vm.mentor.roles.role,
        });

        vm.resetModal();
        vm.onUserAdded([]);
      });
    };

    vm.getMenteeName = function () {
      return getMenteeName(vm.mentor);
    };

    vm.searchUsers = function (pageIndex, searchQuery) {
      // By default just enable search for Learners only (Learner Registration role)
      let includeLearners = true;
      let includeAdmins = false;
      let includeMentors = false;
      if (CurrentPermissionsManager.hasFullCourseAdminPermissions()) {
        // If admin has all-user-types-view permission, enable search for all user types
        includeLearners = true;
        includeAdmins = true;
        includeMentors = true;
      }
      switch (vm.mode) {
        case vm.MODES.MENTEE_ADD:
          return vm.CurrentCourseManager.searchUsers(true, false, false, pageIndex, vm.TABLE_PAGE_SIZE, searchQuery, null, vm.mentor.id);
        case vm.MODES.COURSE_USER_ADD:
          return vm.InstitutionsManager.searchOrgUsers(CurrentCourseManager.course.catalogId, pageIndex, vm.TABLE_PAGE_SIZE, vm.searchQuery, includeLearners, includeAdmins, includeMentors);
        default:
          return null;
      }
    };

    vm.resetModal = function () {
      vm.firstNameDraft = '';
      vm.lastNameDraft = '';
      vm.emailDraft = '';
      vm.selectedRole = vm.CourseRolesManager.noRolesRole;
      vm.savingUserId = -1;
      vm.searchQuery = '';
      vm.isSearchSent = false;
      vm.isSearchLoading = false;
      vm.searchResults = [];
    };

    vm.MODES = MANUAL_ADD_MODES;

    vm.validationConstants = validationConstants;

    // What kind of action this modal is being used for. Determines form submission & search endpoints aside from tweaking UI
    vm.mode = vm.mode || (vm.mentor ? vm.MODES.MENTEE_ADD : vm.MODES.COURSE_USER_ADD);

    vm.searchResults = [];
    vm.selectedRole = CourseRolesManager.noRolesRole;
    vm.UserManagementResources = UserManagementResources;
    vm.CurrentCourseManager = CurrentCourseManager;
    vm.CourseRolesManager = CourseRolesManager;
    vm.AlertMessages = AlertMessages;
    vm.ConfirmationOverlays = ConfirmationOverlays;
    vm.InstitutionsManager = InstitutionsManager;

    vm.uiScrollAdapter = null; // Gets created by angular-ui-scroll
    vm.isSearchSent = false;
    vm.isSearchLoading = false;
    vm.isSaving = false;
    vm.savingUserId = -1;

    vm.learnersOnly = !CurrentPermissionsManager.isConfigAndRegistrationRole() && (CurrentPermissionsManager.isInstructor() || CurrentPermissionsManager.isLearnerRegistrationRole());

    vm.firstNameDraft = '';
    vm.lastNameDraft = '';
    vm.emailDraft = '';

    vm.onRoleChanged = (role) => {
      vm.selectedRole = role;
    };

    vm.getUsersForInfiniteScroll = {
      get: vm.loadUsersInfiniteScroll.bind(vm),
    };

    return vm;
  },
};
