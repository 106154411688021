import PillButton from 'athena/components/pill-button';
import { config } from '@config/pendo.config.json';
import { MentoringProgramRole } from 'redux/schemas/models/mentoring-program-enrollments';
import { css } from '@emotion/react';
import { threeQuartersSpacing } from 'styles/global_defaults/scaffolding';

const dataQATag = config.pendo.athena.mentorshipProgram.participants.filterByRole;
const TABS = [
  {
    role: MentoringProgramRole.MENTORSHIP_PROGRAM_MENTOR,
    dataQA: dataQATag.mentor,
  },
  {
    role: MentoringProgramRole.MENTORSHIP_PROGRAM_MENTEE,
    dataQA: dataQATag.mentee,
  },
  {
    role: MentoringProgramRole.MENTORSHIP_PROGRAM_ADMIN,
    dataQA: dataQATag.programAdmin,
  },
];

type ParticipantTabsProps = {
  setRoleFilter: (role: MentoringProgramRole) => void;
  roleFilter: MentoringProgramRole;
  roleFiltersMeta: Record<MentoringProgramRole, {
    count: number;
    title: string;
    filterTitle: string;
  }>;
};

const styles = css`
  gap: ${threeQuartersSpacing}px;
`;

const ParticipantTabs = ({
  setRoleFilter,
  roleFilter,
  roleFiltersMeta,
}: ParticipantTabsProps) => (
  <div css={styles} className='d-flex flex-row flex-wrap mb-4'>
    {TABS.map(({ role, dataQA }) => {
      const handlePillClick = () => {
        setRoleFilter(role);
      };

      return (
        <PillButton
          key={role}
          onClick={handlePillClick}
          selected={role === roleFilter}
          text={roleFiltersMeta[role].filterTitle}
          dataQa={dataQA}
        />
      );
    })}
  </div>
);

export default ParticipantTabs;
