import { BookmarkType } from 'redux/schemas/models/bookmark';
import { getMaximumWordsOfElementById } from 'shared/services/nv-util';

/* @ngInject */
export default function NvDiscussionComment(
  $q,
  $stateParams,
  $uibModal,
  $uibModalStack,
  CurrentUserManager,
  DiscussionsManager,
  ScrollFocusConnectorFactory,
  TeamWorkspaceManager,
  InstitutionsManager,
  BookmarkModel,
  _,
  config,
  nvUtil,
  $state,
  ReactLecturePageContext,
  ReportModel,
  TimelinesManager,
  ReactTimelineService,
  AlertMessages,
) {
  return {
    restrict: 'A',
    scope: {
      archiveMode: '=?',
      comment: '=',
      context: '@',
      directLinkInfo: '=',
      hideDirectLink: '<',
      lectureVideo: '=?',
      readonlyMode: '=?', // for contributions model, only display
      report: '=?',
      hideVideo: '<?',
      onCommentReady: '&?',
      inModal: '<?',
      pendoTag: '@?',
    },
    require: ['nvDiscussionComment', '?^^nvPostContainer', '?^^nvIndividualSubmission'],
    controller: function ctrl($scope, $element) {
'ngInject';
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const _this = this;
      this.$stateParams = $stateParams;
      this.config = config;

      this.newReplyUiVisible = false;
      this.commentTriggeringReply = null;
      this.currentUserManager = CurrentUserManager;
      this.discussionsManager = DiscussionsManager;
      this.TeamWorkspaceManager = TeamWorkspaceManager;
      this.institutionsManager = InstitutionsManager;
      this.editing = false;
      this.showInstructionsModal = showInstructionsModal;
      this.fetchReplies = fetchReplies;
      this.toggleExpandedReplies = toggleExpandedReplies;
      this.canToggleReplies = canToggleReplies;
      this.adminOptionsVisible = CurrentUserManager.isInstructorForCurrentCourse() || CurrentUserManager.isTeachingAssistantForCurrentCourse();
      this.getShortComment = getShortComment;

      this.requestingReplies = false;

      this.nvUtil = nvUtil;
      this.$state = $state;

      this.directlyLinked = this.directLinkInfo
          && this.directLinkInfo.commentId && this.directLinkInfo.commentId === this.comment.id
          && !this.directLinkInfo.replyId;

      this.repliesVisible = true;
      this.shortComment = '';


      if (this.comment.replyCount) {
        // Load 3 replies per comment by default, 5 replies on subsequent loads
        fetchReplies(3);
      }


      this.hideReplyUi = function () {
        this.newReplyUiVisible = false;
      };

      this.concatenateReplies = function (newReplies) {
        this.replies = this.replies.concat(newReplies);
      };

      this.showReplyUi = function (reply, isEditing) {
        this.newReplyUiVisible = true;

        if (reply) {
          this.isReplyToReply = true;
        } else {
          this.isReplyToReply = false;
        }

        if (reply && !isEditing) {
          this.replyMention = reply.user;
        } else {
          this.replyMention = null;
        }

        this.showReplies().then(() => {
          if (_this.newReplyVisibleHandler) {
            _this.newReplyVisibleHandler(_this.replyMention, isEditing);
          }
        });
      };

      this.registerEditReplyHandler = function (callback) {
        this.editReplyHandler = callback;
      };

      this.registerNewReplyVisibleHandler = function (callback) {
        this.newReplyVisibleHandler = callback;
      };

      this.showReplies = function () {
        this.repliesVisible = true;
        return this.fetchReplies();
      };

      this.toggleLike = function () {
        this.comment.liked = !this.comment.liked;

        if (this.comment.liked) {
          this.comment.like().then(function (response) {
            this.comment.votesCount = response.numLikes;
          });
        } else {
          this.comment.unlike().then(function (response) {
            this.comment.votesCount = response.numLikes;
          });
        }
      };

      this.afterCommentSubmit = function () {
        if (this.editing) {
          this.editing = false;
        }
      };

      this.closeModal = function () {
        $uibModalStack.dismissAll('cancel');
      };

      this.canDelete = function () {
        if (this.context === 'workspace' || this.context === 'workspaceDirectLink') {
          return this.comment.belongsToCurrentUser() || this.adminOptionsVisible;
        }

        return (this.comment.belongsToCurrentUser()
            && !this.discussionsManager.topicIsLocked(this.comment.owner.forum))
          || this.adminOptionsVisible;
      };

      this.canEdit = function () {
        return !this.institutionsManager.institution.disableDiscussionEdits
          && this.canDelete(); // checking other conditions
      };

      let commentCompiled = false;
      let repliesCompiled = false;
      let repliesCount;
      let repliesReady = 0;

      this.onCompile = function () {
        commentCompiled = true;
        setShortComment();
        checkFinalization();
      };

      this.onReplyReady = function () {
        repliesReady += 1;

        if (repliesReady === repliesCount) {
          repliesCompiled = true;
          checkFinalization();
        }
      };

      const checkFinalization = () => {
        if (commentCompiled && (repliesCount !== undefined) ? repliesCompiled : !this.requestingReplies) {
          // When we say that a comment is ready means that the comment content
          // has been compiled by angular (which means content is in document)
          // and replies are compiled by angular if present.
          this.onCommentReady?.();
        }
      };

      this.createBookmark = function () {
        BookmarkModel.createBookmark({
          type: BookmarkType.POST,
          catalogId: $stateParams.catalogId,
          componentId: this.comment.id,
        })
          .then(bookmark => {
            this.comment.bookmarkId = bookmark.id;
          });
      };

      this.highlightBookmark = function () {
        BookmarkModel.highlightBookmark(this.comment.bookmarkId);
      };

      function showInstructionsModal() {
        $uibModal.open({
          templateUrl: 'submissions/templates/informal-feedback-modal.html',
          windowClass: 'informal-feedback-modal',
          controller: 'InformalFeedbackModalCtrl as vm',
          resolve: {
            currentExercise: this.report.exercise,
            currentPeerEvaluation: this.report.exercise.customQuestions,
          },
        });
      }

      function fetchReplies(pageSize?) {
        if (!_this.comment.repliesFetched) {
          _this.requestingReplies = true;
          return _this.comment.fetchReplies({
            betweenId: _this.directLinkInfo ? _this.directLinkInfo.replyId : null,
          }, pageSize)
            .then((result) => {
              _this.comment.repliesFetched = true;
              _this.comment.additionalRepliesBeforeCount = result.additionalCommentsBeforeCount;
              _this.comment.additionalNewRepliesBeforeCount = result.additionalNewCommentsBeforeCount;
              _this.comment.additionalRepliesAfterCount = result.additionalCommentsAfterCount;

              repliesCount = result.comments.length;
              checkFinalization();

              _.each(result.comments, (reply) => {
                DiscussionsManager.setReplyData(reply, _this.comment);
              });
            })
            .catch(() => {
              let message = 'FORM.ERROR_SOMETHING_WRONG';

              if (_this.directLinkInfo.replyId) {
                message = 'DISCUSSIONS.COMMENT_NOT_FOUND';
                if (_this.directLinkInfo.topicId) {
                  $state.go(
                    'post-direct-link',
                    {
                      catalogId: $stateParams.catalogId,
                      postId: _this.directLinkInfo.postId,
                      topicId: _this.directLinkInfo.topicId,
                    },
                  );
                } else if (_this.directLinkInfo.teamId) {
                  $state.go(
                    'team-workspace-post-direct-link',
                    {
                      catalogId: $stateParams.catalogId,
                      postId: _this.directLinkInfo.postId,
                      teamId: _this.directLinkInfo.teamId,
                    },
                  );
                }
              }

              // Adding a unique ID to the alert to prevent the same error from appearing multiple times.
              AlertMessages.error('FORM.OOPS', message, {}, {}, 10000, {}, () => {}, 'reply-error');
            });
        }
        return $q.when();
      }

      function toggleExpandedReplies() {
        _this.repliesVisible = !_this.repliesVisible;


        if (_this.repliesVisible) {
          fetchReplies();
        }
      }

      function canToggleReplies() {
        return true;
      }

      function setShortComment() {
        _this.comment.shortComment = getMaximumWordsOfElementById(`comment_${_this.comment.id}`);
      }

      function getShortComment() {
        return _this.comment.shortComment;
      }
    },
    link(scope, elem, attrs, ctrls) {
      let commentBodyCopy;
      const [commentCtrl] = ctrls;
      const ownerCtrl = ctrls[1] || ctrls[2];

      commentCtrl.updateComment = function () {
        commentBodyCopy = this.comment.body;
        this.editing = true;
        // if (ownerCtrl) {
        //   ownerCtrl.updateComment(commentCtrl.comment);
        // }
      };

      commentCtrl.cancelUpdateComment = function () {
        this.comment.body = commentBodyCopy;
        this.editing = false;
        // if (ownerCtrl) {
        //   ownerCtrl.updateComment(commentCtrl.comment);
        // }
      };

      commentCtrl.deleteComment = function () {
        const deleteConfirmationModal = $uibModal.open({
          backdropClass: 'modal-overlay-backdrop',
          templateUrl: 'discussions/templates/nv-delete-comment-confirmation-overlay.html',
          windowClass: 'modal-overlay',
          controller: function ctrl($scope) {
'ngInject';
            $scope.hasReplies = commentCtrl.comment.replyCount > 0;
            $scope.hasPoints = commentCtrl.comment.pointsReceived;
          },
        });

        deleteConfirmationModal.result.then(() => {
          if (ownerCtrl) {
            ownerCtrl.deleteComment(commentCtrl.comment)?.then((result) => {
              if (result.owner.isPublicFeedbackOn) {
                ReportModel.normalizeSubmissionData(result.owner);

                // using `some` so that we only apply the Submission Feeback tag to the first comment belonging to the current user
                result?.owner?.comments.some((comment) => {
                  if (comment.user.id === CurrentUserManager.user.id) {
                    comment.isPublicFeedback = true;
                    return true;
                  }
                  return false;
                });

                ReactLecturePageContext?.updateAngularComponentStatus?.(
                  'exercise_peer_review',
                  commentCtrl.report.exercise.customQuestions.id,
                  result,
                );

                ReactTimelineService.updateTimeline(commentCtrl.report.exercise.customQuestions.lecturePageId);
                TimelinesManager.updateComponentPointsAndProgress(
                  commentCtrl.report.exercise.customQuestions.lecturePageId,
                  'exercise_peer_review',
                  commentCtrl.report.exercise.customQuestions.id,
                  result.peerEvaluation.pointsReceived,
                  null,
                  result.peerEvaluation.progress,
                );
              }
            });
          } else {
            commentCtrl.comment.owner.removeComment(commentCtrl.comment);
          }
        });
      };

      commentCtrl.handleVideoTimestampClick = function () {
        let videoContainer = elem.closest('.nv-lecture-video-main-body');
        if (!videoContainer.length) {
          videoContainer = elem.closest('.nv-discussion-post');
        }
        ScrollFocusConnectorFactory.scrollTo(null, null, { elem: videoContainer });
        commentCtrl.lectureVideo.seekTo(commentCtrl.comment.videoTimestamp);
      };

      // Scrolling to comment when entire discussion content is loaded to make
      // sure scroll is accurate. Reference: NOV-73717
      scope.$on('discussion-thread-ready', () => {
        if (commentCtrl.directlyLinked) {
          // set up scroll to the directly linked comment
          ScrollFocusConnectorFactory.scrollTo(null, null, { elem, offset: 70 });
        }
      });
    },
    bindToController: true,
    controllerAs: 'vm',
    templateUrl: 'discussions/templates/nv-discussion-comment.html',
  };
}
