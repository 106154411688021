import React from 'react';
import { css } from '@emotion/react';
import { useFormContext, UseFormRegisterReturn } from 'react-hook-form';

import { mergeRefs } from 'shared/react-utils';
import { iconSmallSize } from 'styles/global_defaults/icons';
import { gray1, gray2, gray4, gray7 } from 'styles/global_defaults/colors';
import {
  halfSpacing,
  largeSpacing,
  standardSpacing,
} from 'styles/global_defaults/scaffolding';
import NvIcon from '../nv-icon';

type NativeInputProps = React.ComponentProps<'input'>;
type InputValueType = NativeInputProps['value'];

/**
 * Props we don't need to inherit from native input props.
 */
type OmittedNativeInputProps =
  // Omitting id because we are passing id as the combination of name and value
  // props (see getElementsId function).
  | 'id'
  // Omitting type since this is forced to be a "radio" one.
  | 'type'
  // Omitting because it's now "inputClassName".
  | 'className'
  // Omitting overridden props as well so we can actually override them.
  | keyof OverriddenProps;

/**
 * Props that we want to inherit from the native input props.
 */
// Just omitting the ones we don't need.
type NativeInputPropsInherited = Omit<NativeInputProps, OmittedNativeInputProps>;

// Overriding properties to only make them required (since in
// React.ComponentProps<'input'> they're optional "?")
type OverriddenProps = {
  name: string,
  value: InputValueType,
};

/**
 * Props is the combination of:
 * 1: Our overridden props of the native input.
 * 2: Our "additional" props which is any prop that is not from the native input
 * props.
 * 3. Our inherited props that are directly passed to the input element via
 * "restProps"
 */
type Props = NativeInputPropsInherited & OverriddenProps & {
  // Text of the radio button.
  label: string,
  // "aria-label" attribute value of the input element.
  ariaLabel?: string,
  // Class name of the container element.
  className?: string,
  // Whether we want to use this component in a react-hook-form form.
  // NOTE: Requires "FormProvider" parent wrapper to work.
  withForm?: boolean,
  // Class name of the input element.
  inputClassName?: string,
  // Class name of the label element.
  labelClassName?: string,
  // Render prop for rendering a custom label. (See in learning journeys basics
  // form implementation as an example:
  // "app/learning_journeys/components/basics.tsx")
  customLabel?: () => React.ReactNode,

  // Label icon attributes
  labelIcon?: string,
  labelIconSize?: string,
  labelIconClassName?: string,
  checkboxOverlay?: boolean,
};

/**
 * Returns an id that will be used for input element and "htmlFor" prop of label
 * element.
 */
const getElementsId = (name: string, value: InputValueType) => `${name}-${value}`;

/**
 * NovoEd radio button component.
 * This component can be used as a native radio input type and can be hooked
 * into a react-hook-form form as well if you pass the "withForm" prop as
 * "true".
 */
const NvRadioButton = React.forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    const {
      // Native input props:
      name,
      value,
      onBlur,
      onChange,
      // Additional props:
      label,
      className,
      ariaLabel,
      customLabel,
      inputClassName,
      labelClassName,
      withForm = false,
      labelIcon,
      labelIconSize,
      labelIconClassName,
      checkboxOverlay,
      ...restProps // Native input only props
    } = props;

    const {
      register,
    } = useFormContext() || {};

    let reactHookFormRefHandler;
    let reactHookFormProps: Partial<UseFormRegisterReturn> = {};

    if (withForm) {
      const { ref: registerRef, ...restRegisterProps } = register(name);

      reactHookFormRefHandler = registerRef;
      reactHookFormProps = restRegisterProps;
    }

    const styles = css`
      margin-top: ${halfSpacing}px;
      margin-bottom: ${halfSpacing}px;

      .checkbox-overlay {
        background: white;
        position: absolute;
        height: ${standardSpacing}px;
        width: ${standardSpacing}px;
        left: 0px;
        border-radius: 50%;
        z-index: -1;
      }
      label {
        position: relative;
        padding-left: ${largeSpacing}px;
        display: flex;
        align-items: center;
        line-height: 1.5em;
        margin-bottom: 0;

        &:before {
          display: inline-block;
          max-width: 100%;
          width: ${iconSmallSize}px;
          height: ${iconSmallSize}px;
          border: 1px solid ${gray4};
          background-color: ${gray7};
          content: '';
          border-radius: 5px;
          vertical-align: text-bottom;
          margin-left: -${standardSpacing}px;
          margin-right: ${halfSpacing}px;
          cursor: pointer;
          position: absolute;
          left: ${standardSpacing}px;
          border-radius: 50%;
        }
      }
      input {
        opacity: 0;
        position: absolute;
        &:checked + label:before {
          padding: 4px;
          background-clip: content-box;
          background-color: ${gray1};
          border: 1px solid ${gray2};
          text-align: center;
        }

        &[disabled] {
          cursor: default;

          & + label {
            opacity: 0.5;
            &:before {
              opacity: 0.5;
              cursor: default;
            }
          }
        }
      }
    `;

    const handleBlur = (e) => {
      onBlur?.(e);
      (reactHookFormProps as UseFormRegisterReturn).onBlur?.(e);
    };

    const handleChange = (e) => {
      onChange?.(e);
      (reactHookFormProps as UseFormRegisterReturn).onChange?.(e);
    };

    const id = getElementsId(name, value);

    return (
      <div css={styles} className={className}>
        <input
          {...restProps}
          id={id}
          name={name}
          type='radio'
          value={value}
          onBlur={handleBlur}
          aria-label={ariaLabel}
          onChange={handleChange}
          className={inputClassName}
          ref={withForm ? mergeRefs(ref, reactHookFormRefHandler) : ref}
        />
        <label
          htmlFor={id}
          className={labelClassName}
        >
          {checkboxOverlay && <div className='checkbox-overlay' />}
          {labelIcon && (
            <NvIcon
              icon={labelIcon}
              size={labelIconSize}
              className={labelIconClassName}
            />
          )}
          {label}
        </label>
        {customLabel?.()}
      </div>
    );
  },
);

export default NvRadioButton;
