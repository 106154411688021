import React, { useEffect } from 'react';
import NvRadioButton from 'shared/components/inputs/nv-radio-button';
import { FormProvider, useForm } from 'react-hook-form';
import { css } from '@emotion/react';
import { NvUserAvatar } from 'components/nv-user-avatar';
import t from '../../react-translate';
import { User } from 'redux/schemas/models/my-account';
import { ReactComponent as ArrowRightExpandedIcon } from 'styles/icons/arrow-right-expanded.svg';

interface DiscussionBodyProps {
  currentDiscussionStarterUser: User;
  newDiscussionStarterUser: User;
  onChange: (value: boolean) => void;
}

const styles = css`
  div[role='button'] {
    cursor: default;
  }
`;

const DiscussionBody = ({
  currentDiscussionStarterUser,
  newDiscussionStarterUser,
  onChange,
}: DiscussionBodyProps) => {
  const methods = useForm();
  const { watch } = methods;
  const { bulkStarterUpdate } = watch();
  useEffect(() => {
    if (bulkStarterUpdate !== undefined) {
      onChange(bulkStarterUpdate === 'true');
    }
  }, [onChange, bulkStarterUpdate]);

  return (
    <FormProvider {...methods}>
      <div css={styles} className='d-flex justify-content-center align-items-center'>
        <NvUserAvatar
          tooltipEnabled={false}
          roleTooltipEnabled={false}
          directToProfile={false}
          user={currentDiscussionStarterUser}
          size='md'
          displayName
          inlineName
          borderType='round'
          className='mr-6'
        />
        <ArrowRightExpandedIcon />
        <NvUserAvatar
          tooltipEnabled={false}
          roleTooltipEnabled={false}
          directToProfile={false}
          user={newDiscussionStarterUser}
          size='md'
          displayName
          inlineName
          borderType="round"
          className='ml-6'
        />
      </div>
      <form className="video-practice-form mx-6 my-5">
        <div className="d-flex flex-column text-center text-large-body font-weight-normal">
          <p>{t.DISCUSSIONS.BULK_STARTER_UPDATE.SUBTITLE()}</p>
          <div className="mt-4">
            <NvRadioButton
              withForm
              name="bulkStarterUpdate"
              value="true"
              className="w-100"
              label={t.DISCUSSIONS.BULK_STARTER_UPDATE.YES_CHECKBOX(newDiscussionStarterUser.fullName)}
              labelClassName="text-large-body pl-6"
              checkboxOverlay
            />
            <NvRadioButton
              withForm
              name="bulkStarterUpdate"
              value="false"
              className="w-100"
              label={t.DISCUSSIONS.BULK_STARTER_UPDATE.NO_CHECKBOX()}
              labelClassName="text-large-body pl-6"
              checkboxOverlay
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default DiscussionBody;
