/* @ngInject */
export default function TeamMemberCardCtrl(
  TeamManager,
  CurrentUserManager,
  ConfirmationOverlays,
  $uibModal,
  $state,
  $scope,
  _,
  config,
  ReactLecturePageContext,
) {
  const vm = this;

  vm.config = config;
  vm.CurrentUserManager = CurrentUserManager;
  vm.TeamManager = TeamManager;
  vm.leaveTeam = leaveTeam;
  vm.removeTeamMember = removeTeamMember;
  vm.makeTeamMemberAdmin = makeTeamMemberAdmin;
  vm.makeGroupMemberAdmin = makeGroupMemberAdmin;
  vm.stepDownAsGroupAdmin = stepDownAsGroupAdmin;
  vm.removeGroupMemberAdmin = removeGroupMemberAdmin;
  vm.groupMemberHasDropdown = groupMemberHasDropdown;
  vm.canLeaveTeam = canLeaveTeam;
  vm.canRemoveTeamMember = canRemoveTeamMember;
  vm.canMakeTeamMemberLead = canMakeTeamMemberLead;
  vm.canLeaveGroup = canLeaveGroup;
  vm.canStepDownAsGroupAdmin = canStepDownAsGroupAdmin;
  vm.canRemoveGroupMember = canRemoveGroupMember;
  vm.canRemoveGroupAdmin = canRemoveGroupAdmin;
  vm.canMakeGroupAdmin = canMakeGroupAdmin;
  vm.keepSubmissionCredit = null;

  function leaveTeam() {
    let modalInstance;

    if (TeamManager.currentTeam.currentAccountNumSubmittingsWithTeam > 0) {
      modalInstance = $uibModal.open({
        templateUrl: 'teams/templates/profile/leave-team-with-submissions-modal.html',
        controller: 'AttachModalResolvesToVmCtrl as vm',
        resolve: {
          vmResolves() {
            return {
              TeamManager: vm.TeamManager,
              translateValuesForTeam: $scope.ProfileCtrl.translateValuesForTeam(),
              config: vm.config,
            };
          },
        },
      });
    } else {
      modalInstance = ConfirmationOverlays.openConfirmationModal(
        'teams/templates/profile/leave-team-overlay.html',
        'AttachModalResolvesToVmCtrl as vm',
        {
          vmResolves() {
            return {
              team: vm.TeamManager.currentTeam,
              translateValuesForTeam: $scope.ProfileCtrl.translateValuesForTeam(),
            };
          },
        },
      );
    }

    modalInstance.result.then((result) => {
      vm.TeamManager.currentTeam.currentAccountTeamMember.leaveTeam({
        catalogId: $state.params.catalogId,
        teamId: vm.TeamManager.currentTeam.id,
        removeSubmittings: result ? !result.keepSubmissionCredit : false,
      }).then(() => {
        ReactLecturePageContext?.updateAngularComponentStatus?.(
          'leave_team',
          vm.TeamManager.currentTeam.id,
          {},
        );

        // Updating the memberships state from the manager
        vm.TeamManager.leaveTeam();

        if (vm.TeamManager.currentTeam.numApprovedMembers > 1) {
          vm.TeamManager.currentTeam.removeMember(vm.TeamManager.currentTeam.currentAccountTeamMember.id);
        } else {
          const redirectStateName = (vm.TeamManager.currentTeam.teamSet.isTeam ? 'team-directory' : 'group-directory');
          $state.go(redirectStateName, { catalogId: $state.params.catalogId });
        }

        TeamManager.requestTeamForProfile($state.params.catalogId, $state.params.teamId);
      });
    });
  }

  function removeTeamMember(member) {
    ConfirmationOverlays.openConfirmationModal(
      'teams/templates/profile/remove-team-member-overlay.html',
      'AttachModalResolvesToVmCtrl as vm',
      {
        vmResolves() {
          return {
            member,
            team: vm.TeamManager.currentTeam,
            translateValuesForTeam: $scope.ProfileCtrl.translateValuesForTeam(),
          };
        },
      },
    ).result.then(() => {
      vm.TeamManager.currentTeam.removeTeamMember($state.params.catalogId, member)
        .then(() => {
          ReactLecturePageContext?.updateAngularComponentStatus?.(
            'update_team',
            vm.TeamManager.currentTeam.id,
            {
              team: vm.TeamManager.currentTeam,
            },
          );
        });
    });
  }

  function makeTeamMemberAdmin(member) {
    ConfirmationOverlays.openConfirmationModal(
      'teams/templates/profile/make-team-member-admin-overlay.html',
      'AttachModalResolvesToVmCtrl as vm',
      {
        vmResolves() {
          return {
            member,
            currentAccountTeamMember: TeamManager.currentTeam.currentAccountTeamMember,
            translateValuesForTeam: $scope.ProfileCtrl.translateValuesForTeam(),
          };
        },
      },
    ).result.then(() => {
      vm.TeamManager.currentTeam.makeTeamMemberAdmin($state.params.catalogId, member);
    });
  }

  function makeGroupMemberAdmin(member) {
    ConfirmationOverlays.openConfirmationModal(
      'teams/templates/profile/make-group-member-admin-overlay.html',
      'AttachModalResolvesToVmCtrl as vm',
      {
        vmResolves() {
          return {
            member,
            translateValuesForTeam: $scope.ProfileCtrl.translateValuesForTeam(),
          };
        },
      },
    ).result.then(() => {
      vm.TeamManager.currentTeam.makeTeamMemberAdmin($state.params.catalogId, member);
    });
  }

  function stepDownAsGroupAdmin() {
    ConfirmationOverlays.openConfirmationModal(
      'teams/templates/profile/step-down-as-group-admin-overlay.html',
      'AttachModalResolvesToVmCtrl as vm',
      {
        vmResolves() {
          return {
            translateValuesForTeam: $scope.ProfileCtrl.translateValuesForTeam(),
          };
        },
      },
    ).result.then(() => {
      vm.TeamManager.currentTeam.currentAccountTeamMember.removeAdmin({ catalogId: $state.params.catalogId }).then(() => {
        vm.TeamManager.currentTeam.removeTeamMemberAdmin(vm.TeamManager.currentTeam.currentAccountTeamMember.id);
      });
    });
  }

  function removeGroupMemberAdmin(member) {
    ConfirmationOverlays.openConfirmationModal(
      'teams/templates/profile/remove-group-member-admin-overlay.html',
      'AttachModalResolvesToVmCtrl as vm',
      {
        vmResolves() {
          return {
            member,
            translateValuesForTeam: $scope.ProfileCtrl.translateValuesForTeam(),
          };
        },
      },
    ).result.then(() => {
      member.removeAdmin({ catalogId: $state.params.catalogId });
    });
  }

  function groupMemberHasDropdown(member) {
    return vm.canLeaveGroup(member) || vm.canStepDownAsGroupAdmin(member) || vm.canRemoveGroupMember(member)
        || vm.canRemoveGroupAdmin(member) || vm.canMakeGroupAdmin(member);
  }

  function canLeaveTeam(member) {
    return member.user.id === vm.CurrentUserManager.user.id && vm.TeamManager.currentTeam.teamSet.formedByStudents;
  }

  function canRemoveTeamMember(member) {
    // TODO: Double check these isAdmin's; seems wrong
    return (
      (vm.TeamManager.currentTeam.currentAccountTeamMember && vm.TeamManager.currentTeam.currentAccountTeamMember.isAdmin)
    ) && member.user.id !== vm.CurrentUserManager.user.id;
  }

  function canMakeTeamMemberLead(member) {
    return (
      (vm.TeamManager.currentTeam.currentAccountTeamMember && vm.TeamManager.currentTeam.currentAccountTeamMember.isAdmin)
          && member.user.id !== vm.CurrentUserManager.user.id
    );
  }

  function canLeaveGroup(member) {
    return member.user.id === vm.CurrentUserManager.user.id;
  }

  function canStepDownAsGroupAdmin(member) {
    return vm.TeamManager.currentTeam.currentAccountTeamMember && vm.TeamManager.currentTeam.currentAccountTeamMember.isAdmin
        && member.user.id === vm.CurrentUserManager.user.id;
  }

  function canRemoveGroupMember(member) {
    return (
      (vm.TeamManager.currentTeam.currentAccountTeamMember && vm.TeamManager.currentTeam.currentAccountTeamMember.isAdmin)
    ) && member.user.id !== vm.CurrentUserManager.user.id;
  }

  function canRemoveGroupAdmin(member) {
    return (
      (vm.TeamManager.currentTeam.currentAccountTeamMember && vm.TeamManager.currentTeam.currentAccountTeamMember.isAdmin)
    ) && member.isAdmin && member.user.id !== vm.CurrentUserManager.user.id;
  }

  function canMakeGroupAdmin(member) {
    return (
      (vm.TeamManager.currentTeam.currentAccountTeamMember && vm.TeamManager.currentTeam.currentAccountTeamMember.isAdmin)
    ) && !member.isAdmin;
  }
}
