import { css } from '@emotion/react';
import React, { FunctionComponent, Children, useRef, useState, useCallback, useEffect, useLayoutEffect } from 'react';
import NvIcon from 'shared/components/nv-icon';
import { hexToRgbaString, gray2 } from 'styles/global_defaults/colors';
import useWindowResize from 'shared/hooks/use-window-resize';
import { handheld, isRtl } from 'styles/global_defaults/media-queries';
import { standardSpacing } from 'styles/global_defaults/scaffolding';

/**
 * Gallery component supports following functionality:
 *  - Gallery displays center of window when all elements visible
 *  - Left arrow hidden when first element is active
 *  - Right arrow hidden when remaining elements are visible
 *  - Resizing window re-performs calculations
 */

type ArrowProps = {
  onClick: (e: any) => void;
  visible: boolean;
  bottomPadding?: number;
};

const RightArrow = React.forwardRef<HTMLButtonElement, ArrowProps>(({
  onClick,
  visible,
  bottomPadding,
}, ref) => {
  const arrowStyles = css`
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    background-color: ${hexToRgbaString(gray2, 0.8)};
    width: 40px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 0;
    box-shadow: none;

    ${handheld(css`
      height: 80px;
      top: 50%;
      transform: translateY(calc(-50% - ${bottomPadding / 2}px));
    `)}

    .icon {
      color: white;
    }
  `;

  if (visible) {
    return (
      <button ref={ref} type='button' onClick={onClick} css={arrowStyles} aria-hidden='true' className='carousel-next'>
        <NvIcon icon='arrow-right' size='small' />
      </button>
    );
  }

  return null;
});

const LeftArrow = React.forwardRef<HTMLButtonElement, ArrowProps>(({
  onClick,
  visible,
  bottomPadding,
}, ref) => {
  const arrowStyles = css`
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${hexToRgbaString(gray2, 0.8)};
    width: 40px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 0;
    box-shadow: none;

    ${handheld(css`
      height: 80px;
      top: 50%;
      transform: translateY(calc(-50% - ${bottomPadding / 2}px));
    `)}

    .icon {
      color: white;
    }
  `;

  if (visible) {
    return (
      <button ref={ref} type='button' onClick={onClick} css={arrowStyles} aria-hidden='true' className='carousel-prev'>
        <NvIcon icon='arrow-left' size='small' />
      </button>
    );
  }

  return null;
});

const SlideItem = ({
  children,
  spacing,
  index,
  onFocus,
}) => {
  const styles = css`
    flex: 0 0 auto;

    ${spacing && css`
      &:first-of-type {
        padding-left: ${spacing}px;
      }
      &:not(:last-of-type) {
       padding-right: ${spacing}px;
      }
    `}
  `;

  return (
    <div key={index} css={styles} onFocus={onFocus}>
      {children}
    </div>
  );
};

type NvSubmissionGalleryProps = {
  spacing?: number;
  topPadding?: number;
  arrowsHidden?: boolean;
  bottomPadding?: number;
  loadMoreItems?: () => void;
  alignCenter?: boolean;
  backgroundColor?: string,
};

const NvSubmissionGallery: FunctionComponent<NvSubmissionGalleryProps> = ({
  children,
  spacing,
  arrowsHidden,
  topPadding = 0,
  bottomPadding = 0,
  loadMoreItems,
  alignCenter = true,
  backgroundColor = 'transparent',
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [remainingElementsVisible, setRemainingElementsVisible] = useState(false);

  const sliderRef = useRef(null);
  const childRefs = useRef([]);
  const leftButtonRef = useRef(null);
  const rightButtonRef = useRef(null);

  const [isChildVisible, setIsChildVisible] = useState<boolean[]>(Array(Children.count(children)).fill(true));

  const sliderRefCallback = useCallback((node: HTMLElement) => {
    if (node) {
      const { children: childElements } = node;
      const { width: containerWidth } = node.getBoundingClientRect();

      const remainingElements = Array.from(childElements).slice(activeIndex);

      let sumChildWidth = 0;
      remainingElements.forEach((child, i) => {
        const childWidth = (child as HTMLElement).getBoundingClientRect().width;
        sumChildWidth += childWidth;
      });

      const restElementsShown = sumChildWidth <= containerWidth;
      if (restElementsShown && loadMoreItems) {
        loadMoreItems();
      }
      setRemainingElementsVisible(restElementsShown);
    }

    sliderRef.current = node;
  }, [activeIndex]);

  useWindowResize(() => {
    sliderRefCallback(sliderRef.current);
  }, 50, false, [sliderRef, activeIndex]);

  useEffect(() => {
    sliderRefCallback(sliderRef.current);
  });

  useLayoutEffect(() => {
    const galleryWidth = sliderRef.current.offsetWidth;
    const childWidth = childRefs.current.length
      ? childRefs.current[0].offsetWidth
      : 1;
    const visibleItemsCount = Math.floor(galleryWidth / childWidth);
    const currentMaxVisibleIndex = activeIndex + (visibleItemsCount - 1);
    setIsChildVisible(childVisibleValues => childVisibleValues.map((visible, index) => index >= activeIndex && index <= currentMaxVisibleIndex));
  }, [sliderRef, childRefs, activeIndex, spacing]);

  const transformActiveElement = (forward) => {
    if (forward) {
      const activeElement: HTMLElement = sliderRef.current.children[activeIndex];
      const { width } = activeElement.getBoundingClientRect();

      sliderRef.current.style.transform += `translateX(${width * (isRtl() ? 1 : -1)}px)`;
      sliderRef.current.style.transition += 'transform 0.5s linear';
    } else {
      const activeElement: HTMLElement = sliderRef.current.children[activeIndex - 1];
      const { width } = activeElement.getBoundingClientRect();

      sliderRef.current.style.transform += `translateX(${width * (isRtl() ? -1 : 1)}px)`;
      sliderRef.current.style.transition += 'transform 0.5s linear';
    }
  };

  const goToPrevSlide = () => {
    if (activeIndex > 0) {
      transformActiveElement(false);
      setActiveIndex(index => index - 1);
    }
  };

  const goToNextSlide = () => {
    if (activeIndex < Children.count(children) - 1) {
      transformActiveElement(true);
      setActiveIndex(index => index + 1);
    }
  };

  /**
   * Checks if one of the buttons is over the element focused.
   * In case they are overlapped, then we call goToPrevSlide or
   * goToNextSlide depending on the Arrow Button (Left or Right) overlapped.
   * This happened when tabbing (NOV-85681)
   */
  const onSlideItemFocus = (event) => {
    const element = event.currentTarget;
    const elementBoundaries = element.getBoundingClientRect();
    // Getting the right boundary of the LeftArrow button
    const endingPositionLeftButton = leftButtonRef.current?.offsetLeft + leftButtonRef.current?.offsetWidth;
    // Getting the left boundary of the RightArrow button
    const startingPositionRightButton = rightButtonRef.current?.offsetLeft;
    if (endingPositionLeftButton && elementBoundaries.left < endingPositionLeftButton) {
      goToPrevSlide();
    }
    if (startingPositionRightButton && startingPositionRightButton < elementBoundaries.left + element.offsetWidth) {
      goToNextSlide();
    }
  };

  const newChildren = Children.map(children, (child: JSX.Element, index) => (
    <SlideItem
      index={index}
      spacing={spacing}
      onFocus={(event) => { onSlideItemFocus(event); }}
    >
      {React.cloneElement(child, {
        isFocusable: isChildVisible[index],
        ref: (childRef) => { childRefs.current[index] = childRef; },
      })}
    </SlideItem>
  ));

  const centerMode = remainingElementsVisible && activeIndex === 0;

  const leftArrowVisible = !arrowsHidden && !!activeIndex;
  const rightArrowVisible = !arrowsHidden && !remainingElementsVisible;

  const styles = css`
    position: relative;
    overflow: hidden;
    width: 100%;
    background-color: ${backgroundColor};
    ${topPadding && css`
      padding-top: ${topPadding}px;
    `};
    ${bottomPadding && css`
      padding-bottom: ${bottomPadding}px;
    `};

    .slider {
      display: flex;
      margin-left: ${leftArrowVisible ? standardSpacing : 0}px;

      ${centerMode && alignCenter && css`
        justify-content: center;
      `}
    }
  `;

  return (
    <div css={styles} className='nv-submission-gallery'>
      <LeftArrow
        ref={leftButtonRef}
        onClick={goToPrevSlide}
        visible={leftArrowVisible}
        bottomPadding={bottomPadding}
      />
      <div className='slider' ref={sliderRefCallback}>
        {newChildren}
      </div>
      <RightArrow
        ref={rightButtonRef}
        onClick={goToNextSlide}
        visible={rightArrowVisible}
        bottomPadding={bottomPadding}
      />
    </div>
  );
};

export default NvSubmissionGallery;
