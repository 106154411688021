/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-shadow */
import prodPathReplace from 'shared/prod-path-rewrite';
import { ExternalToolWebLinkTypes } from 'redux/schemas/models/external-tool';
import { isEmpty } from 'lodash';
import t from '../../../react-translate';
import { STATUS } from '../../services/components/embed-lecture-component-model';
import { NvDropdownAlign } from '../../../shared/components/inputs/nv-dropdown';
import store from '../../../redux/store';
import { createAutoCompletionDropdownItem } from '../../components/auto-completion-dropdown-item';
import ActivityTypesDropdown from '../../components/activity-types-dropdown';
import { ComponentType, ExternalToolType } from '../../../redux/schemas/models/lecture-component';
import { TextAlign } from '../../../shared/components/nv-tooltip.tsx';
import { getAutoCompletionProps } from './lecture-component-edit-dropdown';
import {
  getSkillTagsFromAngular,
  focusOnFirstElement,
} from '../../../shared/services/nv-util.js';

export default {
  bindings: {
    lectureComponent: '<',
    editMode: '<',
    restrictedEditMode: '<',
    reorderMode: '<',
    index: '<',
    context: '<',
    linkedEditMode: '<',
  },
  controller: function ctrl(
    $controller,
    $element,
    $scope,
    $sce,
    $timeout,
    $translate,
    ConfirmationOverlays,
    CurrentCourseManager,
    AlertMessages,
    LectureComponentsHelper,
    TimelinesManager,
    ReactTimelineService,
    ExternalToolLectureComponentModel,
    config,
    CurrentUserManager,
    PusherManager,
    humps,
    _,
    $uibModal,
  ) {
'ngInject';
    // Temp spot-fix for https://novoed.atlassian.net/browse/NOV-64179
    if (!this.lectureComponent || isEmpty(this.lectureComponent?.externalTool)) {
      return;
    }

    const ASPECT_RATIO_TO_CLASS = {
      '4:3': 'embed-responsive-4by3',
      '1:1': 'embed-responsive-1by1',
      '21:9': 'embed-responsive-21by9',
      '16:9': 'embed-responsive-16by9',
      a4: 'embed-responsive-a4',
      letter: 'embed-responsive-letter',
      manual: 'embed-responsive-manual',
    };

    const ASPECT_RATIO_TO_HEIGHT = {
      '4:3': 4 / 3,
      '1:1': 1,
      '21:9': 21 / 9,
      '16:9': 16 / 9,
      a4: 5 / 7,
      letter: 7 / 9,
      manual: 1,
    };

    const ACTIVITY_TO_STATUS_TRANSLATE_KEY = {
      quiz: 'QUIZZES.TAKE',
      survey: 'QUIZZES.TAKE',
      assignment: 'EXERCISES.SUBMIT',
      feedback: 'EVALUATIONS.EVALUATE',
      video: 'LECTURE_VIDEO.WATCH',
      reading: 'LECTURE_PAGES.COMPONENTS.THIRD_PARTY_ACTIVITIES.READING_VERB',
      discussion: 'LECTURE_PAGES.COMPONENTS.DISCUSSION.DISCUSS',
      audio: 'LECTURE_PAGES.COMPONENTS.THIRD_PARTY_ACTIVITIES.AUDIO_VERB',
    };

    const PUSHER_EVENT_NAME = 'submission_completed';
    const SCORM_EVENT_NAME = 'scorm_check_progress';
    const MARK_DONE_EVENT = 'mark_as_done_trigger';

    const activityTypesDropdown = () => {
      const { sharedProps } = this.context;
      const todoOptions = vm.getActivityTypes(sharedProps);
      const newSharedProps = { ...sharedProps, todoOptions };
      const index = todoOptions.items.findIndex(i => i.selected);

      const onCollapse = (e) => {
        const isToggleButton = e.target.closest('.status');
        if (!isToggleButton) {
          vm.isActivityTypesDropdownOpen = false;
        }
        $scope.$digest();
      };

      return (
        <ActivityTypesDropdown
          sharedProps={newSharedProps}
          align={NvDropdownAlign.CENTER}
          show
          initialIndex={index}
          onCollapse={onCollapse}
        />
      );
    };

    angular.extend(this, $controller('LectureComponentBaseCtrl'));
    const vm = this;
    vm.$element = $element;
    vm.config = config;
    vm.webLinkTypes = ExternalToolWebLinkTypes;
    vm.fullScreenMode = false;
    vm.pusherChannel = null;
    $scope.ActivityTypesDropdown = activityTypesDropdown;
    vm.completedActivities = new Map();
    vm.uibModalInstance = null;

    const { id: courseId } = CurrentCourseManager.course;

    $scope.$on('$destroy', () => {
      // Force clearing out the iframe when we leave this page to reduce IE memory leak issues
      // See https://stackoverflow.com/questions/34652242/internet-explorer-11-spa-iframe-memory-leak
      vm.$element.find('.embed-responsive > iframe').each((i, iframe) => {
        iframe.src = 'about:blank';
        iframe.parentNode.removeChild(iframe);
        window.CollectGarbage?.();
      });
    });

    $scope.$watch('vm.lectureComponent.externalTool.launchUrl', () => {
      updateUrl();
    });

    $scope.$watch('vm.editMode', () => {
      if (vm.lectureComponent.externalTool.isResizing) {
        vm.doneResizing(false);
      }
    });

    function getDefaultHeight(width) {
      const aspectRatio = ASPECT_RATIO_TO_HEIGHT[vm.lectureComponent.externalTool.aspectRatio];
      let height = 0;
      if (aspectRatio) {
        height = width / aspectRatio;
      } else {
        height = (width * vm.lectureComponent.externalTool.aspectRatio) / 100;
      }
      return height;
    }

    function handleResizing() {
      const wrapper = $element.find('#embedded-component');
      const handles = 's';
      const width = wrapper.width();
      const borderSize = 8;
      const defaultHeight = getDefaultHeight(width);
      const setManualHeight = (height) => {
        wrapper.css('--manual-height', `${height - borderSize}px`);
      };
      const resize = (_, ui) => {
        setManualHeight(ui.size.height);
      };

      wrapper.css('height', `${defaultHeight}px`);
      wrapper.resizable({ handles, resize });
      setManualHeight(defaultHeight);
    }

    function resetResizing() {
      const wrapper = $element.find('#embedded-component');
      if (wrapper) {
        const instance = wrapper.resizable('instance');
        if (instance) {
          wrapper.css('height', '0');
          wrapper.resizable('destroy');
        }
      }
    }

    vm.doneResizing = (save = true) => {
      if (save) {
        const getAspectRatio = () => {
          const wrapper = $element.find('#embedded-component');
          const width = wrapper.width();
          const height = wrapper.height();
          const aspectRatio = (height / width) * 100;
          return aspectRatio;
        };
        vm.lectureComponent.externalTool.aspectRatio = getAspectRatio();
      }
      vm.lectureComponent.externalTool.isResizing = false;
      vm.lectureComponent.save();
      resetResizing();
    };

    $scope.$watch('vm.lectureComponent.externalTool.isResizing', () => {
      const { isResizing } = vm.lectureComponent.externalTool;
      const handler = isResizing ? handleResizing : resetResizing;
      $timeout(handler);
    });

    $scope.$watch(' vm.lectureComponent.externalTool.aspectRatio', (ar) => {
      if (Object.keys(ASPECT_RATIO_TO_CLASS).includes(ar)) {
        vm.oldAspectRatio = ar;
      }
    });

    updateUrl();
    vm.lectureComponent.setUrlUpdateCallback(updateUrl);

    function updateUrl() {
      vm.launchUrls = [$sce.trustAsResourceUrl(vm.lectureComponent.externalTool.launchUrl)];
    }

    vm.getStatusTranslateKey = () => ACTIVITY_TO_STATUS_TRANSLATE_KEY[vm.lectureComponent.externalTool.activityType];

    vm.getProgressTranslateKey = () => (vm.isActivity() ? 'LECTURE_PAGES.COMPONENTS.ACTIVITY.STATUSES.DONE' : 'EXERCISES.SUBMITTED');

    vm.toggleFullScreen = () => {
      vm.fullScreenMode = !vm.fullScreenMode;

      $timeout(() => {
        vm.uibModalInstance = $uibModal.open({
          templateUrl: 'lecture_pages/templates/components/third-party-activity-fullscreen-modal.html',
          windowClass: 'full-screen-modal-handheld third-party-full-screen',
          controller: function ctrl($scope) {
'ngInject';
            $scope.launchUrls = vm.launchUrls ?? [];
            $scope.closeModal = vm.closeModal;
            $scope.getEmbeddedClass = vm.getEmbeddedClass;
            $scope.pendo = config.pendo;
          },
        });
      }).then(() => {
        document.querySelector('div#app-root').setAttribute('inert', '');
        focusOnFirstElement('.modal-dialog');

        $timeout(() => {
          const iframe = document.querySelector('.modal-dialog iframe');

          if (!iframe) return;
          iframe.onload = () => {
            focusOnFirstElement('.modal-dialog');
          };
        });
      });
    };

    vm.closeModal = () => {
      vm.uibModalInstance.dismiss('cancel');
      $timeout(() => {
        vm.fullScreenMode = false;
      }).then(() => {
        document.querySelector('div#app-root').removeAttribute('inert');
      });
    };

    vm.getResponsiveClass = () => (
      [ASPECT_RATIO_TO_CLASS[vm.lectureComponent.externalTool.aspectRatio]]
    );

    vm.getEmbeddedClass = () => {
      if (vm.lectureComponent.externalTool.isResizing) return 'resizable';
      const { aspectRatio } = vm.lectureComponent.externalTool;
      let aspectRatioClass = ASPECT_RATIO_TO_CLASS[aspectRatio];
      if (Number(aspectRatio) || aspectRatio === 'manual') {
        const wrapper = $element.find('#embedded-component');
        wrapper.css('--manual-aspect-ratio', `${aspectRatio ?? '100'}%`);
        aspectRatioClass = ASPECT_RATIO_TO_CLASS.manual;
      }
      return ['embed-responsive', aspectRatioClass];
    };

    // TODO: Move all "mark done" logic out of directives
    vm.initiateMarkDone = () => {
      const modalInstance = ConfirmationOverlays.openConfirmationModal('lecture_pages/templates/mark-done-confirmation-overlay.html');

      modalInstance.result.then(() => {
        vm.lectureComponent.markDone().then(() => {
          const hasPoints = CurrentCourseManager.course.gamificationEnabled && vm.lectureComponent.externalTool.pointsReceived;
          const skillTags = getSkillTagsFromAngular(vm.lectureComponent.id);
          const hasSkillTags = skillTags.length > 0;
          if (hasPoints || hasSkillTags) {
            if (hasPoints) {
              ReactTimelineService.updateTimeline(vm.lectureComponent.lecturePage.id);
              TimelinesManager.updateComponentPointsAndProgress(
                vm.lectureComponent.lecturePage.id,
                vm.lectureComponent.type,
                vm.lectureComponent.id,
                vm.lectureComponent.externalTool.pointsReceived,
              );
            }
            if (!vm.completedActivities.has(vm.lectureComponent.externalTool.id)) {
              vm.completedActivities.set(vm.lectureComponent.externalTool.id, true);
              vm.showPointsModal(vm.lectureComponent.externalTool, { skillTags });
            }
          } else {
            ReactTimelineService.updateTimeline(vm.lectureComponent.lecturePage.id);
            TimelinesManager.updateComponentProgress(
              vm.lectureComponent.lecturePage.id,
              vm.lectureComponent.type,
              vm.lectureComponent.id,
              vm.lectureComponent.externalTool.progress,
            );
          }
        });
      });
    };

    vm.requestProgress = (endpoint, errorHandler, failHandler) => {
      $timeout(() => {
        endpoint().then(() => {
          vm.lectureComponent.externalTool.checkInProgress = false;

          if (vm.lectureComponent.externalTool.status === 'completed') {
            const lectureId = store.getState().app.lecturePage.currentLectureId;
            const { type: lectureComponentType, id: lectureComponentId } = vm.lectureComponent;
            vm.lectureComponent.externalTool.progress = vm.lectureComponent.externalTool.status;
            const skillTags = getSkillTagsFromAngular(vm.lectureComponent.id);
            const hasSkillTags = skillTags.length > 0;
            const hasPoints = CurrentCourseManager.course.gamificationEnabled && vm.lectureComponent.externalTool.pointsReceived;

            if (hasPoints || hasSkillTags) {
              if (hasPoints) {
                ReactTimelineService.updateTimeline(lectureId);
                TimelinesManager.updateComponentPointsAndProgress(
                  lectureId,
                  lectureComponentType,
                  lectureComponentId,
                  vm.lectureComponent.externalTool.pointsReceived
                );
              }
              if (!vm.completedActivities.has(vm.lectureComponent.externalTool.id)) {
                vm.completedActivities.set(vm.lectureComponent.externalTool.id, true);
                vm.showPointsModal(vm.lectureComponent.externalTool, { skillTags });
              }
            } else {
              ReactTimelineService.updateTimeline(lectureId);
              TimelinesManager.updateComponentProgress(lectureId, lectureComponentType, lectureComponentId, vm.lectureComponent.externalTool.progress);
            }
          } else {
            vm.lectureComponent.externalTool.progress = STATUS.IN_PROGRESS;
            errorHandler();
          }
        }).catch(() => {
          vm.lectureComponent.externalTool.progress = STATUS.IN_PROGRESS;
          vm.lectureComponent.externalTool.checkInProgress = false;
          if (failHandler) failHandler();
          else errorHandler();
        });
      });
    };

    vm.checkProgress = () => {
      const endpoint = vm.lectureComponent.checkProgress.bind(vm.lectureComponent);
      vm.lectureComponent.externalTool.checkInProgress = true;
      vm.requestProgress(endpoint, () => {
        AlertMessages.warning('', 'LECTURE_PAGES.COMPONENTS.THIRD_PARTY_ACTIVITIES.ERROR_CHECKING_PROGRESS', {}, {});
      });
    };

    vm.checkSCORMProgress = () => {
      vm.isUnloaded = true;
      vm.lectureComponent.externalTool.checkInProgress = true;
      $timeout(() => {
        vm.isUnloaded = false;
      }, 1000);
    };

    vm.shouldShowSpinner = () => vm.isUnloaded && vm.isScorm();

    vm.shouldShowActivity = () => {
      const show = (
        vm.lectureComponent.externalTool.launchUrl
        && vm.lectureComponent.externalTool.toolType !== vm.webLinkTypes.WEB_LINK
        && !vm.shouldShowSpinner()
      );
      vm.isVisible = show;
      return show;
    };

    vm.isActivityLoaded = () => {
      if (vm.lectureComponent.externalTool.checkInProgress) {
        const endpoint = vm.lectureComponent.checkSCORMProgress.bind(vm.lectureComponent);
        vm.requestProgress(endpoint, () => {
          AlertMessages.warning('', 'LECTURE_PAGES.COMPONENTS.THIRD_PARTY_ACTIVITIES.ERROR_CHECKING_SCORM_PROGRESS', {}, {}, 10000, null, null, null, 'WARNING_CHECK_MY_PROGRESS');
        }, () => {
          AlertMessages.warning('', 'LECTURE_PAGES.COMPONENTS.THIRD_PARTY_ACTIVITIES.ERROR_CHECKING_PROGRESS', {}, {}, 10000, null, null, null, 'WARNING_CHECK_MY_PROGRESS');
        });
      }
    };

    $scope.$watch('vm.isVisible', (isVisible) => {
      if (isVisible && vm.isScorm()) {
        $('#scorm-activity').on('load', vm.isActivityLoaded);
      }
    });

    vm.editBasics = () => {
      vm.lectureComponent.createDraft();

      const modalInstance = LectureComponentsHelper.showEditModal(vm.lectureComponent);

      modalInstance.result.then(() => {
        const closeModal = LectureComponentsHelper.showSavingOverlay();

        vm.lectureComponent.saveDraft().then(closeModal, closeModal);
      });
    };

    vm.toggleTodo = () => {
      vm.lectureComponent.isOpenedActivityListFromEditMenu = false;
      if (vm.lectureComponent.isTodo()) {
        if (vm.lectureComponent.isRequiredForCompletion()) {
          ConfirmationOverlays.openConfirmationModal('lecture_pages/templates/components/todo-confirmation-overlay.html',
            'AttachModalResolvesToVmCtrl', {
              vmResolves() {
                return {
                  lectureComponent: vm.lectureComponent,
                  CurrentCourseManager,
                };
              },
            })
            .result.then(() => {
              vm.lectureComponent.disableToDo();
            });
        } else {
          vm.lectureComponent.disableToDo();
        }
      } else {
        vm.openActivityTypesDropdown();
      }
    };

    vm.openWebLinkSelector = (event) => {
      vm.lectureComponent.isOpenedActivityListFromEditMenu = true;
      vm.openActivityTypesDropdown(event);
    };

    vm.openActivityTypesDropdown = (event) => {
      $timeout(() => {
        const { target } = event;
        const isSwitch = target.closest('.auto-complete-switch');
        if (!isSwitch) {
          vm.isActivityTypesDropdownOpen = !vm.isActivityTypesDropdownOpen;
        }
      });
    };

    vm.selectActivityType = (activityType) => {
      vm.lectureComponent.updateActivityType(activityType.name);
    };

    vm.selectActivityTypeAsTodo = (activityType) => {
      vm.lectureComponent.updateActivityTypeAsTodo(activityType.name);
      vm.isActivityTypesDropdownOpen = false;
    };

    vm.getLinkImage = () => prodPathReplace('images/file-weblinks.png');

    vm.linkClick = () => {
      if (!vm.editMode && !vm.lectureComponent.isCompleted()) vm.lectureComponent.updateActivityProgress();
    };

    vm.shouldShowScormDescription = () => (
      vm.shouldAutoComplete()
      && !vm.editMode
      && vm.lectureComponent.shouldShowMarkDone()
      && !vm.lectureComponent.isCompleted()
    );

    vm.shouldDisableMarkAsDone = () => {
      const isCompletedOrEditMode = vm.lectureComponent.isCompleted?.()
        || vm.editMode
        || vm.restrictedEditMode
        || vm.reorderMode;
      const isNotWebLink = vm.lectureComponent.externalTool.toolType !== vm.webLinkTypes.WEB_LINK;
      const isDisabled = vm.lectureComponent.shouldDisableMarkDone
        && vm.lectureComponent.shouldDisableMarkDone(vm.editMode);
      const checkInProgress = vm.lectureComponent.externalTool?.checkInProgress;
      const shouldDisableMarkAsDone = (isCompletedOrEditMode && isNotWebLink) || isDisabled || checkInProgress;

      return shouldDisableMarkAsDone;
    };

    vm.shouldDisableCheckMyProgress = () => vm.shouldAutoComplete() && vm.shouldDisableMarkAsDone();

    vm.shouldDropdownUp = () => (vm.index >= 1 && vm.lectureComponent.externalTool.toolType === vm.webLinkTypes.WEB_LINK);

    vm.isActivity = () => (vm.lectureComponent.externalTool.isTodo || vm.lectureComponent.externalTool.pointsConfiguration?.points);

    vm.showUrl = () => (_.isEmpty(vm.lectureComponent.externalTool.name));

    vm.getWebLinkName = () => (vm.showUrl() ? vm.lectureComponent.externalTool.url : vm.lectureComponent.externalTool.name);

    vm.getWebLinkTitle = () => (!_.isEmpty(vm.lectureComponent.externalTool.displayText)
      ? vm.lectureComponent.externalTool.displayText : vm.getWebLinkName());

    // Setting displayText default as the name value
    if (_.isEmpty(vm.lectureComponent?.externalTool?.displayText)) {
      vm.lectureComponent.externalTool.displayText = vm.lectureComponent.externalTool.name;
    }

    function updatePoints(pusherData, showPointsModal) {
      const lectureId = store.getState().app.lecturePage.currentLectureId;
      const { type: lectureComponentType, id: lectureComponentId } = vm.lectureComponent;

      vm.lectureComponent.externalTool.progress = STATUS.COMPLETED;
      vm.lectureComponent.externalTool.pointsReceived = pusherData?.pointsReceived;
      vm.lectureComponent.externalTool.leaderboardPoints = pusherData?.leaderboardPoints;

      const skillTags = getSkillTagsFromAngular(vm.lectureComponent.id);
      const hasSkillTags = skillTags.length > 0;
      const hasPoints = CurrentCourseManager.course.gamificationEnabled && vm.lectureComponent.externalTool.pointsReceived;

      if (hasPoints || hasSkillTags) {
        if (hasPoints) {
          ReactTimelineService.updateTimeline(lectureId);
          TimelinesManager.updateComponentPointsAndProgress(
            lectureId,
            lectureComponentType,
            lectureComponentId,
            vm.lectureComponent.externalTool.pointsReceived,
          );
        }
        if (showPointsModal) {
          vm.showPointsModal(vm.lectureComponent.externalTool, { skillTags });
        }
      } else {
        ReactTimelineService.updateTimeline(lectureId);
        TimelinesManager.updateComponentProgress(
          lectureId,
          lectureComponentType,
          lectureComponentId,
          vm.lectureComponent.externalTool.progress,
        );
      }
    }

    function updateActivity(isCompleted, pusherData, showPointsModal) {
      if (
        pusherData.externalToolId === vm.lectureComponent.externalTool.id
        && !vm.lectureComponent.isCompleted()
        && isCompleted
      ) {
        updatePoints(pusherData, showPointsModal);
      }
    }

    function handleScormEvent(pusherData) {
      pusherData = humps.camelizeKeys(pusherData);
      const isCompleted = pusherData.progress === STATUS.COMPLETED;
      let showModal = false;
      if (isCompleted) {
        showModal = !vm.completedActivities.has(pusherData.externalToolId);
        vm.completedActivities.set(pusherData.externalToolId, true);
      }
      updateActivity(isCompleted, pusherData, showModal);
    }

    function handleMarkDoneEvent(pusherData) {
      pusherData = humps.camelizeKeys(pusherData);
      updateActivity(true, pusherData, true);
    }

    function handleSubmissionCompleted(pusherData) {
      pusherData = humps.camelizeKeys(pusherData);
      updateActivity(true, pusherData, false);
    }

    function setupPusherNotifications() {
      const anonymized = CurrentUserManager.user.anonymizedIdentifier.substr(0, 10);
      vm.pusherChannel = PusherManager.initializeUserCourseChannel(anonymized, courseId);
      vm.pusherChannel.bind(PUSHER_EVENT_NAME, handleSubmissionCompleted);
      if (vm.lectureComponent.trueType === 'ScormExternalToolLectureComponent') {
        vm.pusherChannel.bind(SCORM_EVENT_NAME, handleScormEvent);
      } else if (!isTypeOf(ExternalToolType.LTI)) {
        vm.pusherChannel.bind(MARK_DONE_EVENT, handleMarkDoneEvent);
      }
    }

    /**
     * Evaluates if the lecture component type (trueType)
     * matches with some of the received types (type)
     * @param {(string|string[])} type One or multiple types to compare
     * @returns {boolean} If 'type' matches with the lecture component types
     */
    function isTypeOf(type) {
      const { trueType } = vm.lectureComponent;
      return Array.isArray(type)
        ? type.some((t) => t === trueType)
        : trueType === type;
    }

    setupPusherNotifications();

    vm.showActivityTypeDropdown = () => (
      (vm.editMode || vm.linkedEditMode)
      && !vm.lectureComponent.uploadInProgress
      && vm.isActivity()
    );

    vm.getAutocompleteItems = () => {
      if (vm.isScorm()) {
        // `Autocomplete` item and `Change Activity Type` item will be included
        if (vm.isActivity()) return 4;
        // Any items will be included
        return 0;
      }
      // Only `Change Activity Type` item will be included
      return 2;
    };

    vm.isTypeOf = (type) => vm.lectureComponent.externalTool.toolType === type;

    /**
     * Unfortunately, `disableSetActive`, divider, and header dropdown items
     * don't get ignored in the dropdown selected index calculation.
     * So we have to offset this value by 3 or 4 (We have an extra change
     * activity option if the component is a todo) to account for those.
     */
    vm.getAspectRatioListActiveIndex = () => {
      const autocompleteItems = vm.getAutocompleteItems();
      const activityItems = vm.isActivity() ? 2 : 1;
      const isWebLink = vm.isTypeOf(vm.webLinkTypes.WEB_LINK);

      let index = ExternalToolLectureComponentModel.ASPECT_RATIOS.findIndex((ar) => (
        ar.name === vm.lectureComponent.externalTool.aspectRatio
      ));

      // if index is not found we should ask if it's a number
      // in that case the aspectRatio is manual which is the latest item
      if (index < 0 && Number(vm.lectureComponent.externalTool.aspectRatio)) {
        index = ExternalToolLectureComponentModel.ASPECT_RATIOS.length - 1;
      }

      return isWebLink ? 0 : index + activityItems + autocompleteItems;
    };

    $scope.$on('$destroy', () => {
      $('body').removeClass('focus-fullscreen');

      if (vm.pusherChannel) {
        const anonymized = CurrentUserManager.user.anonymizedIdentifier.substr(0, 10);
        PusherManager.removeUserCourseChannel(anonymized, courseId);
      }
    });

    vm.getAutocompletionItem = () => createAutoCompletionDropdownItem(getAutoCompletionProps(this.lectureComponent));

    vm.getActivityTypes = (sharedProps) => {
      const activityTypes = this.lectureComponent.activitySelectDropdownOptions(sharedProps);
      const items = vm.canShowAutocompletionItem()
        ? [...vm.getAutocompletionItem(), ...activityTypes.items]
        : activityTypes.items;
      return { items };
    };

    vm.canShowAutocompletionItem = () => (vm.lectureComponent.type === ComponentType.EXTERNAL_TOOL
      && vm.lectureComponent.trueType !== ExternalToolType.LTI);

    vm.isScorm = () => vm.lectureComponent.trueType === 'ScormExternalToolLectureComponent';

    vm.shouldAutoComplete = () => vm.isScorm() && vm.lectureComponent.externalTool.autocompleteScorm;

    vm.shouldAutoMarkDone = () => (
      isTypeOf([ExternalToolType.WEB_EMBED, ExternalToolType.WEB_LINK])
      && vm.lectureComponent.externalTool.autocompleteScorm
    );

    vm.setEditMenu = (isTodo) => {
      const { sharedProps, setSharedProps } = this.context;
      const extraOptions = [];
      let draftSharedProps = {};

      if (vm.lectureComponent.canEditBasics) {
        extraOptions.push(sharedProps.extraOptions.getEditOption(
          t.LECTURE_PAGES.COMPONENTS.DROPDOWN.EDIT_BASICS(),
          vm.lectureComponent.lecturePage.isLinked, {
            disabledTooltip: t.LECTURE_PAGES.COMPONENTS.SET_UP_FROM_COLLECTION_TOOLTIP(),
            tooltipTextAlign: TextAlign.LEFT,
          },
        ));
      }

      if (vm.isActivity() && !CurrentCourseManager.course.isContentManagementCollection) {
        extraOptions.push({
          type: 'text',
          text: t.LECTURE_PAGES.COMPONENTS.THIRD_PARTY_ACTIVITIES.CHANGE_ACTIVITY_TYPE(),
          callback: () => {
            vm.lectureComponent.isOpenedActivityListFromEditMenu = true;
            $timeout(() => {
              draftSharedProps.extraOptions.forceShowActivities();
            });
            $scope.$digest();
          },
          disableSetActive: true,
        });
      }

      if (vm.lectureComponent.externalTool.toolType !== vm.webLinkTypes.WEB_LINK) {
        if (vm.lectureComponent.canEditBasics || vm.isActivity()) {
          extraOptions.push({ type: 'divider' });
        }

        extraOptions.push({
          type: 'header',
          title: t.LECTURE_PAGES.COMPONENTS.ATTACHMENT_LIST.EMBEDDING_STYLE.HEADER(),
        },
        ...(ExternalToolLectureComponentModel.ASPECT_RATIOS.map(aspectRatio => ({
          type: 'text',
          text: $translate.instant(aspectRatio.translateKey),
          preventClosing: false,
          callback: () => {
            this.lectureComponent.updateAspectRatio(aspectRatio.name);
            // See the comment next to `model.$scope = $scope;` in `angular-lecture-component.tsx`
            // about this.
            // TODO: Make this automatic/universal or otherwise evaluate how widely this is needed

            $scope.$digest();
          },
          ...(aspectRatio.dataQa ? { dataQa: aspectRatio.dataQa } : {}),
        }))));
      }

      const todoOptions = vm.getActivityTypes(sharedProps);
      draftSharedProps = {
        ...sharedProps,
        extraOptions: {
          ...sharedProps.extraOptions,
          ...{
            options: extraOptions,
            showActive: true,
            initialSelectedIndex: vm.getAspectRatioListActiveIndex(),
          },
        },
        todoOptions,
      };

      setSharedProps(draftSharedProps);
    };

    $scope.$watch('vm.lectureComponent.externalTool.isTodo', (isTodo) => {
      vm.setEditMenu(isTodo);
    });
  },
  controllerAs: 'vm',
  templateUrl: 'lecture_pages/templates/components/nv-external-tool-lecture-component.html',
};
