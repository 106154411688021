
import { css } from '@emotion/react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import t from 'react-translate';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { find, snakeCase } from 'lodash';
import { isEmpty } from 'underscore';
import { AngularServicesContext } from 'react-app';

// redux
import { useAppDispatch } from 'redux/store';
import { addUsersToCourse, searchCourseUsers } from 'redux/actions/users';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { unEnrollUserFromCourse } from 'redux/actions/org-users';
import { fetchCollection } from 'redux/actions/collections';
import { getCollectionRoles } from 'redux/selectors/roles';
import { getCourse } from 'redux/selectors/course';
import { RootState } from 'redux/schemas';
import { CourseUser } from 'redux/schemas/models/my-account';
import { NameAndEmailFormSource } from 'redux/schemas/models/user';
import { CourseRole } from 'redux/schemas/models/org-level-roles';
import { Role } from 'redux/schemas/models/role';

// styles
import { createGridStyles, threeQuartersSpacing, doubleSpacing, largeSpacing, topHeaderHeight, institutionBrandBorderWidth, standardSpacing } from 'styles/global_defaults/scaffolding';
import { black, gray2, gray5, primary, white } from 'styles/global_defaults/colors';
import { openSansCondensed } from 'styles/global_defaults/fonts';

// components;
import NvDropdown, { NvDropdownAlign, NvDropdownButtonStyle, NvDropdownCustomItem, NvDropdownOption } from 'shared/components/inputs/nv-dropdown';
import { NvResponsiveTabsDisplayType, NvTab } from 'shared/components/nv-responsive-tabs';
import NvResponsiveTabsRow from 'shared/components/nv-responsive-tabs-row';
import NvModal, { ModalType } from 'shared/components/nv-modal';
import NvResponsiveTable, { NvResponsiveTableColumn, NvTableRowProps } from 'shared/components/nv-responsive-table';
import LoadingPlaceholder from 'shared/components/loading-placeholder';
import NvUserAvatar from 'components/nv-user-avatar';
import EditNameAndEmailModal, { NameAndEmailFormUser } from 'institutions/components/edit-name-and-email/edit-modal';
import NvPopover from 'shared/components/nv-popover';
import NvIcon from 'shared/components/nv-icon';
import { Button } from 'react-bootstrap';
import L1PageHeader from 'shared/components/l1-page-header';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import AddUserModal, { CollectionRoleItem } from './add-user-modal';
import UserBulkRemoveModal from './user-bulk-remove-modal';
import UserBulkUploadModal from './user-bulk-upload-modal';

enum Tab {
  USER_MANAGEMENT = 'user_management',
}

const PAGE_SIZE = 30;

export const getPopoverContent = (role: string) => {
  const rolekey = snakeCase(role).toUpperCase();
  return t.INSTITUTIONS.CONTENT_LIBRARY.USER_MANAGEMENT.ROLE_DETAIL[rolekey]?.();
};

const CollectionUserManagement = () => {
  const styles = css`
    .back-button {
      color: ${black} !important;
    }

    .collection-title {
      width: calc(100vw - 120px);
    }

    .unenroll-content {
      max-height: calc(100vh - 120px);
      overflow: scroll;
    }

    .btn-panel {
      width: 260px;

      .nv-dropdown, .bs4-dropdown {
        width: 100%;
      }
    }

    .tab-text {
      font-family: ${openSansCondensed};
    }

    .tab-content {
      // total height - (top header height + tab header height)
      min-height: calc(100vh - 115px);
      overflow-y: scroll;

      // In reference to full-content-height used for Lessons User management screen height
      .nv-responsive-table {
        overflow: visible;
        height: calc(100vh - calc(${topHeaderHeight}px + ${institutionBrandBorderWidth}px + calc(${standardSpacing}px * 3)))
      }

      .no-results-panel {
        padding-top: 100px;

        .icon:not(.icon-smallest) {
          font-size: 100px;
          margin-bottom: ${largeSpacing}px;
        }
      }

      .user-row {
        .edit-role {
          .bs4-dropdown {
            width: 160px;
          }
        }

        .full-name {
          white-space: pre-wrap;
        }

        &:hover, &.options-open {
          background-color: ${white};

          .actions-icon {
            display: flex;
            background-color: ${gray5};
            .icon {
              color: ${gray2}
            }
          }
        }

        .actions-icon {
          display: none;
          width: 50px;
          height: 50px;

          &:hover {
            background-color: ${primary};
            .icon {
              color: ${white}
            }
          }
        }
      }
    }
  `;

  const UserRowSyles = (columnsWidth) => css`
    .name {
      width: ${columnsWidth.name}
    }
    .email {
      width: ${columnsWidth.email}
    }
    .role {
      width: ${columnsWidth.role}
    }
    .actions {
      width: ${columnsWidth.actions};
    }
    .edit-role {
      width: 30%;
    }
  `;
  const { catalogId, collectionId } = useParams<{ collectionId: string, catalogId: string }>();
  const currentInstitution = useSelector(getCurrentInstitution);
  const collection = useSelector((state) => getCourse(state, catalogId));
  const courseUsers = useSelector((state) => state.models.courseUsers[catalogId]);

  const [activeTab, setActiveTab] = useState(Tab.USER_MANAGEMENT);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showAddUserBulkModal, setShowAddUserBulkModal] = useState(false);
  const [showUserBulkRemoveModal, setShowUserBulkRemoveModal] = useState(false);
  // Toggling loadUserTable to reload the users list.
  const [loadUserTable, setLoadUsersTable] = useState(true);

  const { $scope, $state, $timeout } = useContext(AngularServicesContext);
  const history = useHistory();
  const dispatch = useAppDispatch();

  /**
   * We are using react routing in content management pages. So the angular state
   * change not working as expected. Triggering a render on the angular side to
   * keep angular states updated with the current page.
   */
  useEffect(() => {
    $timeout(() => {
      if ($state.current.name !== 'content-library-collection-user-management') {
        $scope.$apply();
      }
    });
  }, []);

  useEffect(() => {
    dispatch(fetchCollection(collectionId));
  }, [collectionId, dispatch]);

  const closeAddUserModal = (reloadlist = false) => {
    if (reloadlist) {
      setLoadUsersTable(false);
    }

    setShowAddUserModal(false);
  };
  const closeAddBulkUserModal = (reloadList = false) => {
    if (reloadList) {
      setLoadUsersTable(false);
    }

    setShowAddUserBulkModal(false);
  };
  const getStoreCourseUserData = (state: RootState) => state.models.courseUsers[catalogId];

  /**
   * Resetting the loadTable value to true when it changes to false for
   * reloading the users list table.
   */
  useEffect(() => {
    if (!loadUserTable) {
      setLoadUsersTable(true);
    }
  }, [loadUserTable]);

  const fetchParams = {
    catalogId,
    courseAdmins: true,
    courseLearners: true,
    courseMentors: true,
    filterByRole: null,
    queryTerm: null,
  };
  const tabs: NvTab[] = [
    {
      text: t.INSTITUTIONS.CONTENT_LIBRARY.USER_MANAGEMENT.TAB(),
      onClick: () => setActiveTab(Tab.USER_MANAGEMENT),
    },
  ];

  const manageUsersItems: NvDropdownOption[] = [
    {
      type: 'text',
      text: t.INSTITUTIONS.CONTENT_LIBRARY.USER_MANAGEMENT.MANAGE_USERS.MANUALLY_ADD(),
      callback: () => setShowAddUserModal(true),
    },
    {
      type: 'text',
      text: t.INSTITUTIONS.CONTENT_LIBRARY.USER_MANAGEMENT.MANAGE_USERS.BULK_ADD(),
      callback: () => setShowAddUserBulkModal(true),
    },
  ];

  // Only showing the bulk unenrolling option if any user is enrolled in that collection.
  if (!isEmpty(courseUsers)) {
    manageUsersItems.push(
      {
        type: 'divider',
      },
      {
        type: 'text',
        class: 'text-danger',
        text: t.INSTITUTIONS.CONTENT_LIBRARY.USER_MANAGEMENT.MANAGE_USERS.BULK_REMOVE(),
        callback: () => setShowUserBulkRemoveModal(true),
      },
    );
  }

  const manageUsersBtn = (
    <NvDropdown
      key='manual-btn'
      title={t.INSTITUTIONS.CONTENT_LIBRARY.USER_MANAGEMENT.MANAGE_USERS.BUTTON_TEXT()}
      buttonStyle={NvDropdownButtonStyle.BUTTON}
      items={manageUsersItems}
      buttonClass='text-medium light ml-auto'
      allowOverflow
    />
  );

  const tabStyles = {
    ...createGridStyles(1, 2),
    marginLeft: `${doubleSpacing}px`,
    marginRight: `${threeQuartersSpacing}px`,
  };

  const tableColumnsWidth = {
    name: '35%',
    email: '35%',
    role: '20%',
    actions: '10%',
  };

  const tableColumns: NvResponsiveTableColumn[] = [
    {
      name: t.INSTITUTIONS.CONTENT_LIBRARY.USER_MANAGEMENT.USER_TABLE.NAME(),
      className: 'name-cell',
      gridWidth: tableColumnsWidth.name,
    },
    {
      name: t.INSTITUTIONS.CONTENT_LIBRARY.USER_MANAGEMENT.USER_TABLE.EMAIL(),
      className: 'email-cell',
      gridWidth: tableColumnsWidth.email,
    },
    {
      name: t.INSTITUTIONS.CONTENT_LIBRARY.USER_MANAGEMENT.USER_TABLE.ROLE(),
      className: 'role-cell',
      gridWidth: tableColumnsWidth.role,
    },
    {
      name: '',
      className: 'actions-cell',
      headerClassName: 'justify-content-end',
      gridWidth: tableColumnsWidth.actions,
    },
  ];

  const LoadingRow = (rowProps: { rowIndex: number }, rowEnd: number) => (
    <div className='px-5' style={createGridStyles(1, rowProps.rowIndex, rowEnd)}>
      <LoadingPlaceholder className=' mt-5' />
    </div>
  );

  const noResultsTextComponent = (
    <div className='text-regular text-gray-3 bold'>
      {t.INSTITUTIONS.CONTENT_LIBRARY.USER_MANAGEMENT.USER_TABLE.NO_USER_YET()}
    </div>
  );

  const CourseUserRow = (rowProps: NvTableRowProps<CourseUser, {}, { reloadList: () => void }>) => {
    const { data, rowIndex, extraProps: { reloadList } } = rowProps;
    const collectionRoles: CourseRole[] = useSelector<RootState, Role>(getCollectionRoles);

    const [showEditNameAndEmailModal, setShowEditNameAndEmailModal] = useState(false);
    const [isEditRole, setIsEditRole] = useState(false);
    const [newUserCourseRoleId, setNewUserCourseRoleId] = useState<number>(data.user.roles.courseRoleId);
    const [savingEdit, setSavingEdit] = useState<boolean>(false);
    const [isOptionsOpen, setIsOptionsOpen] = useState<boolean>(false);

    const confirmDelete = useCallback(() => {
      const translationValues = {
        firstName: data.user.firstName,
        lastName: data.user.lastName,
        courseAlias: 'collection',
      };

      dispatch(openConfirmationDialog({
        title: t.INSTITUTIONS.CONTENT_LIBRARY.USER_MANAGEMENT.USER_TABLE.REMOVE_USER.TITLE(),
        bodyText: t.INSTITUTIONS.CONTENT_LIBRARY.USER_MANAGEMENT.USER_TABLE.REMOVE_USER.DESCRIPTION(data.user.firstName, data.user.fullName),
        confirmText: t.FORM.YES_SURE(),
        onConfirm: () => {
          dispatch(unEnrollUserFromCourse({
            user: data.user,
            catalogId,
            institutionId: currentInstitution.id,
            isJourney: false,
            isCollection: true,
            translationValues,
          }));
        },
      }));
    }, [data.user]);

    const actionItems: NvDropdownOption[] = [
      {
        type: 'text',
        text: t.INSTITUTIONS.CONTENT_LIBRARY.USER_MANAGEMENT.USER_TABLE.ROW_ACTIONS.EDIT_NAME(),
        callback: () => setShowEditNameAndEmailModal(true),
      },
      {
        type: 'text',
        text: t.INSTITUTIONS.CONTENT_LIBRARY.USER_MANAGEMENT.USER_TABLE.ROW_ACTIONS.EDIT_ROLE(),
        callback: () => setIsEditRole(true),
      },
      {
        type: 'divider',
      },
      {
        type: 'text',
        text: t.INSTITUTIONS.CONTENT_LIBRARY.USER_MANAGEMENT.USER_TABLE.ROW_ACTIONS.REMOVE(),
        class: 'text-danger',
        callback: () => confirmDelete(),
      },
    ];

    const collectionRoleItems: NvDropdownCustomItem[] = collectionRoles.map(role => ({
      id: role.id,
      type: 'custom',
      customItem: <CollectionRoleItem role={role} onClick={setNewUserCourseRoleId} />,
    }));

    const getRoleItemTitle = (): string => find(collectionRoles, (role) => role.id === newUserCourseRoleId)?.name;

    const onEditSave = () => {
      setSavingEdit(true);
      const payload = {
        catalogId,
        courseRoleId: newUserCourseRoleId.toString(),
        users: [{
          firstName: data.user.firstName,
          lastName: data.user.lastName,
          email: data.user.email,
        }],
      };

      dispatch(addUsersToCourse(payload)).then((res) => {
        setSavingEdit(false);
        setIsEditRole(false);
        reloadList();
      });
    };

    const onOptionsToggle = (isOpen: boolean) => setIsOptionsOpen(isOpen);

    return (
      <React.Fragment>
        <div
          className={`user-row d-flex align-items-center w-100 text-black border-bottom border-gray-5 ${isOptionsOpen ? 'options-open' : ''}`}
          style={createGridStyles(1, rowIndex, tableColumns.length + 1, rowIndex + 1)}
          css={UserRowSyles(tableColumnsWidth)}
        >
          <div className='name d-flex align-items-center py-1 px-4'>
            <NvUserAvatar
              tooltipEnabled={false}
              directToProfile={false}
              displayRoleBadge={false}
              user={data.user}
              size='md'
              borderType='round'
            />
            <span className='pl-2 text-regular full-name text-primary'>
              {data.user.fullName}
            </span>
          </div>
          <div className='email text-regular'>{data.user.email}</div>
          {isEditRole ? (
            <div className='edit-role d-flex'>
              <NvDropdown
                items={collectionRoleItems}
                buttonStyle={NvDropdownButtonStyle.FORM}
                titleClass='text-regular'
                title={getRoleItemTitle()}
                tooltip={data.isOrgManager ? t.INSTITUTIONS.CONTENT_LIBRARY.USER_MANAGEMENT.MANAGE_ACCESS_MODAL.DISABLED_ROLE_TOOLTIP() : ''}
                disabled={data.isOrgManager}
              />
              <Button
                className='ml-2'
                variant='primary'
                onClick={onEditSave}
                disabled={data.user.roles.courseRoleId === newUserCourseRoleId || savingEdit}
              >
                {savingEdit ? t.FORM.SAVING() : t.FORM.SAVE()}
              </Button>
              <Button
                className='ml-2'
                variant='secondary'
                onClick={() => setIsEditRole(false)}
              >
                {t.FORM.CANCEL()}
              </Button>
            </div>
          ) : (
            <React.Fragment>
              <div className='role d-flex align-items-center text-regular font-weight-bolder'>
                <span>{data.user.roles.role}</span>
                <NvPopover
                  content={(
                    <div>{getPopoverContent(data.user.roles.role)}</div>
                  )}
                  placement='top'
                  preventOverflow={false}
                  className='pl-1'
                  showOnHover
                >
                  <div>
                    <NvIcon icon='info' size='xss-smallest' className='color-primary' />
                  </div>
                </NvPopover>
              </div>
              <div className='actions d-flex align-items-center justify-content-end'>
                <NvDropdown
                  buttonStyle={NvDropdownButtonStyle.CUSTOM}
                  align={NvDropdownAlign.LEFT}
                  items={actionItems}
                  onToggle={onOptionsToggle}
                  allowOverflow
                  customTarget={() => (
                    <div className='actions-icon align-items-center justify-content-center'>
                      <NvIcon icon='edit' size='xss-smallest' />
                    </div>
                  )}
                />
              </div>
            </React.Fragment>
          )}
        </div>

        {showEditNameAndEmailModal && (
          <EditNameAndEmailModal
            user={data.user as NameAndEmailFormUser}
            source={NameAndEmailFormSource.CONTENT_MANAGEMENT_COLLECTION}
            closeCallback={() => setShowEditNameAndEmailModal(false)}
            catalogId={catalogId}
            courseUserId={data.id}
          />
        )}
      </React.Fragment>
    );
  };

  const goToBack = () => {
    history.goBack();
  };

  const closeBulkUserUnenrollModal = (reloadlist = false) => {
    if (reloadlist) {
      setLoadUsersTable(false);
    }
    setShowUserBulkRemoveModal(false);
  };

  const getTitleLink = () => ({
    title: currentInstitution.name,
    href: $state.href('institution-dashboard', { institutionId: currentInstitution.id }),
  });

  return (
    <div css={styles}>
      <L1PageHeader
        title={collection?.title}
        titleClassName='collection-title ellipsis'
        titleLink={getTitleLink()}
        backButton
        backAction={goToBack}
      />
      <NvResponsiveTabsRow
        style={tabStyles}
        defaultTabs={tabs}
        tabType={NvResponsiveTabsDisplayType.TEXT_ONLY}
        iconBtns={[manageUsersBtn]}
        revertActiveTab={0} // Currently only has one tab so used zero directly.
      />
      <div className='tab-content bg-gray-7 pb-5 border-top border-gray-6'>
        <div>
          {activeTab === Tab.USER_MANAGEMENT && loadUserTable && (
            <NvResponsiveTable<CourseUser>
              columns={tableColumns}
              fetchData={searchCourseUsers as any}
              fetchParams={fetchParams}
              pagedFetchParams={{}}
              rowComponent={CourseUserRow}
              rowProps={{ reloadList: () => setLoadUsersTable(false) }}
              loadingComponent={(rawProps) => LoadingRow(rawProps, tableColumns.length + 1)}
              dataKey='id'
              cacheDataKey='id'
              cacheLookup={getStoreCourseUserData}
              clearSearch={() => {}}
              pageSize={PAGE_SIZE}
              style={createGridStyles(1, 3)}
              noResultsIcon='profile'
              noResultsTextComponent={noResultsTextComponent}
              hoverDisabled
            />
          )}
        </div>
        <NvModal
          show={showAddUserModal}
          onClose={() => closeAddUserModal()}
          width={720}
          header={t.INSTITUTIONS.CONTENT_LIBRARY.USER_MANAGEMENT.ADD_USER_MODAL.HEADER()}
          body={(
            <AddUserModal onClose={closeAddUserModal} />
        )}
          bodyClassName='px-4'
          fullHeight={false}
          type={ModalType.DYNAMIC}
        />
        <NvModal
          type={ModalType.DYNAMIC}
          header={t.USER_MANAGEMENT.UPLOAD_CSV()}
          body={(
            <UserBulkUploadModal
              closeModal={closeAddBulkUserModal}
            />
        )}
          show={showAddUserBulkModal}
          onClose={() => closeAddBulkUserModal()}
          width={720}
          fullHeight={false}
        />
        <NvModal
          type={ModalType.DYNAMIC}
          header={t.USER_MANAGEMENT.CSV_UPLOAD_MODAL.BULK_UNENROLL.HEADER()}
          show={showUserBulkRemoveModal}
          onClose={() => closeBulkUserUnenrollModal()}
          width={720}
          fullHeight={false}
          bodyClassName='p-0'
          body={(
            <UserBulkRemoveModal
              closeModal={closeBulkUserUnenrollModal}
            />
          )}
        />
      </div>
    </div>
  );
};

export default CollectionUserManagement;

