import React from 'react';
import moment from 'moment';
import { css } from '@emotion/react';
import Truncate from 'react-truncate';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import t from 'react-translate';

import { RootState } from 'redux/schemas';
import { useAppDispatch } from 'redux/store';
import NvIcon from 'shared/components/nv-icon';
import NvTooltip from 'shared/components/nv-tooltip';
import { AngularServicesContext } from 'react-app';
import BaseCard from 'offerings/components/base-card';
import { Enrollment } from 'redux/schemas/models/my-account';
import { PopoversContainerContext } from 'shared/react-utils';
import ClickableContainer from 'components/clickable-container';
import { getCurrentInstitution } from 'redux/selectors/institutions';
import { getCurrentUser, getUserEnrollments } from 'redux/selectors/users';
import EmptyOverlay from 'offerings/components/offering-card/empty-overlay';
import { updateEnrollment, removeUserEnrollment } from 'redux/actions/users';
import LockedOverlay from 'offerings/components/offering-card/locked-overlay';
import OfferedToOverlay from 'offerings/components/offering-card/offered-to-overlay';
import CompletedStatusOverlay from 'offerings/components/offering-card/completed-status-overlay';
import UnmetRequirementOverlay from 'offerings/components/offering-card/unmet-requirement-overlay';
import { useOfferingCardProgressConext } from 'offerings/components/offering-card/offering-card-progress-context';
import {
  auditCourse,
  reenrollCourse,
  withdrawCourse,
} from 'redux/actions/courses';
import {
  Course,
  CourseJourneyDetail,
  CourseRegistrationType,
} from 'redux/schemas/models/course';
import {
  openSans,
  boldFontWeight,
  headerLineHeight,
  normalFontWeight,
  openSansCondensed,
  textSmallFontSize,
  semiBoldFontWeight,
  textSmallLineHeight,
  textExtraLargeFontSize,
} from 'styles/global_defaults/fonts';
import {
  black,
  gray1,
  gray2,
  gray3,
  gray5,
  gray6,
  primary,
  success,
  warning,
  hexToRgbaString,
  white,
  gray8,
} from 'styles/global_defaults/colors';
import {
  ReactComponent as EnrolledAvatarIcon,
} from 'styles/icons/enrolled-avatar.svg';
import {
  halfSpacing,
  largeSpacing,
  quarterSpacing,
  standardSpacing,
  threeQuartersSpacing,
} from 'styles/global_defaults/scaffolding';
import NvDropdown, {
  NvDropdownAlign,
  NvDropdownOption,
  NvDropdownButtonStyle,
} from 'shared/components/inputs/nv-dropdown';
import LayerTransitioner, {
  DEFAULT_TRANSITION_DURATION,
} from 'offerings/components/layer-transitioner';
import { VisitedLearningJourneySchema } from 'redux/schemas/app/learning-journey';
import { updateVisitedJourney } from 'redux/actions/learning-journeys';
import NvViewMore, { ViewMoreBehavior, ViewMoreCollapser } from 'shared/components/nv-view-more';
import { sortSkillTags } from 'redux/selectors/skills-feedback';
import { config } from '../../../../config/config.json';

const OFFERING_NAME_LINE_HEIGHT = largeSpacing;

const POSITION_INDICATOR_COLOR = hexToRgbaString(gray3, 0.8);
const POSITION_INDICATOR_COLOR_COMPLETED = hexToRgbaString(success, 0.8);

const activeCardHeaderBackdropStyles = css`
  .offering-card-header .offering-backdrop-layer {
    background-color: ${hexToRgbaString(black, 0.5)};
  }
`;

export const OfferingContext = React.createContext<Partial<Course> & {
  inEnrolledJourneys: CourseJourneyDetail[],
}>(null);

type Props = {
  offering: Partial<Course>,
  locked?: boolean,
  position?: number,
  className?: string,
  parent?: Course,
  style?: React.CSSProperties,
  // When we complete a journey, the corresponding enrollment is updated in a
  // backend background process, which means it takes time in order to mark it
  // as completed, so this property allows us to infer whether a journey is
  // completed using other criteria (not the enrollment).
  useJourneyInferedCompletion?: boolean,
  isFocusable?: boolean,
  journey?: VisitedLearningJourneySchema,
  dataQa?: string,
  dataQaId?: string,
  openInNewTab?: boolean,
  showSkillTags?: boolean,
};

const OfferingCard = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    style,
    parent,
    offering,
    position,
    className,
    locked = false,
    useJourneyInferedCompletion = false,
    isFocusable,
    journey: journeyCardInfo,
    dataQa,
    dataQaId,
    openInNewTab = false,
    showSkillTags = false,
  } = props;

  const currentMoment = moment();
  const dispatch = useAppDispatch();
  const { completionStats } = offering;
  const isOfferingIndependent = !parent;
  const isInProgram = !!parent?.isProgram;
  const isInJourney = !!parent?.isJourney;
  const currentUser = useSelector(getCurrentUser);
  const [isFocused, setIsFocused] = React.useState(false);
  const currentInstitution = useSelector(getCurrentInstitution);
  const releaseDateMoment = moment(offering.officialReleaseDate);
  const isUpcoming = releaseDateMoment > currentMoment;
  const contextContainer = React.useContext(PopoversContainerContext);
  const isOfferingCloseDatePast = moment(offering.closeDate).isBefore(moment());
  const brandingColorToUse = offering.headerColor || currentInstitution.brandColor;
  const isOfferingReleaseDatePast = moment(offering.releaseDate).isBefore(moment());
  const userCourse = useSelector((state) => state.models.enrollments[offering.userCourse]);
  const isOfferingRegistrationCloseDatePast = moment(offering.registrationCloseDate).isBefore(moment());
  const userEnrollments = useSelector<RootState, Enrollment[]>((state) => getUserEnrollments(state, currentUser.id));
  const isEnrolledToParent = !!userEnrollments.find((enrollment) => enrollment.courseCatalogId === parent?.catalogId);
  const currentUserEnrollement = userEnrollments.find((enrollment) => enrollment.courseId === offering.id);
  const currentLectureId = offering?.currentLecture?.currentLecture?.activityId ?? offering?.currentLecture?.firstLecture?.id;

  // Any child offering card of OfferingCardProgressContextProvider will
  // impose/force a specific progress.
  const imposedProgress = useOfferingCardProgressConext();

  // Any reference to offering progress in this file should refer to this
  // variable to work properly.
  const actualProgress = imposedProgress || currentUserEnrollement?.completionProgress;

  const {
    $state,
    $scope,
    RailsRoutes,
    CurrentUserManager,
  } = React.useContext(AngularServicesContext);

  const isCurrentUserEnrolled = !!currentUserEnrollement?.isEnrolled;
  const isInProgress = actualProgress === 'in_progress';
  const requiredCoursesCompleted = completionStats?.collections.reduce((coursesCompleted, { completed, required }) => coursesCompleted + Math.min(completed, required), 0);
  const regularIsComplete = actualProgress === 'completed' || requiredCoursesCompleted >= completionStats?.required;
  const journeyInferedIsComplete = completionStats?.collections.every(({ completed, required }) => completed >= required);
  const isComplete = (offering.isJourney && useJourneyInferedCompletion) ? (regularIsComplete || journeyInferedIsComplete) : regularIsComplete;

  const accessCloseDate = moment(
    currentUserEnrollement?.enrolledAt,
  ).add(offering.enrollmentLimitInDays, 'days');
  const selfPacedDaysRemaining = Math.ceil(
    ((accessCloseDate as unknown as number) - (moment() as unknown as number))
      / (3600 * 24 * 1000),
  );

  const isIncomplete = isCurrentUserEnrolled
    ? (!regularIsComplete && (
      (!!offering.closeDate && isOfferingCloseDatePast)
        || (offering.isSelfPaced && offering.enrollmentLimitInDays && selfPacedDaysRemaining <= 0)
    ))
    : false;

  const inEnrolledJourneys = offering.inJourneys?.filter(
    (journey) => userEnrollments.some(
      (enrollment) => enrollment.courseCatalogId === journey.catalogId,
    ),
  ) ?? [];

  const navigateToHome = () => {
    const stateToNavigate = offering.isJourney ? 'learning-journey-home' : 'course-home';

    // Directly changing the location hash does the fix for this bug:
    // https://novoed.atlassian.net/browse/NOV-72837.
    // The reasoning behind this solution is very very complex since required me
    // to read some source code of angular-ui-router library.
    // Basically the issue was that when we navigate to any state
    // programatically (via "$state.go(...)") the view is updated first and the
    // browser page url changes after. The opposite thing happens for when you
    // change the url first, if you change the url first it will be handled
    // first by the browser (and therefore push the new angular state page into
    // the browser history) and after that the view is updated. So in the first
    // case where the view is updated first and the url at last, novoed
    // redirections that occur inside a "resolve" of the state we want to
    // navigate to and include the "location: 'replace'" option, will make the
    // redirecting page replace the actual page you were before navigating to
    // any state because we know that "resolve" option code of an
    // angular-ui-router state runs before the state is "resolved" and therefore
    // no history entry is being added (because we are redirecting before state
    // is resolved) which makes the redirection page replace the current page.
    // So if we ensure history entry is added firstly (that's why the hash
    // approach) the state replacement will work as expected since entry is
    // always added to history when navigating directly with url.
    // Given that explanation, you can take a look at angular state
    // "course-home-abstract" (which is parent of "course-home", the state we
    // are intending to navigate to) and look at its "requestCourseHome" resolve
    // entry, there is where they are redirecting with replacement to
    // "course-flyer" state.
    // As an additional note, "course-home-abstract" is the only single state
    // that does replacement redirection as of today.
    if (!openInNewTab) {
      window.location.hash = $state.href(stateToNavigate, {
        catalogId: offering.catalogId,
      });
    } else {
      window.open($state.href(stateToNavigate, {
        catalogId: offering.catalogId,
      }), '_blank');
    }
  };

  const navigateToFlyer = () => {
    if (!openInNewTab) {
      $state.go('course-flyer', { catalogId: offering.catalogId });
    } else {
      window.open($state.href('course-flyer', {
        catalogId: offering.catalogId,
      }), '_blank');
    }
  };

  const navigateToCurrentLecture = () => {
    if (currentLectureId) {
      if (!openInNewTab) {
        $state.go('lecture-page', {
          id: currentLectureId,
          catalogId: offering.catalogId,
        });
      } else {
        window.open($state.href('lecture-page', {
          id: currentLectureId,
          catalogId: offering.catalogId,
        }), '_blank');
      }
    } else {
      navigateToHome();
    }
  };

  const numberOfOfferingNameLines = isInProgram ? 2 : 3;

  const getDefaultBackdropStyles = () => {
    if (isInJourney && locked) {
      return css`
        background-color: ${hexToRgbaString(black, 0.5)};
      `;
    }

    if (isComplete) {
      return css`
        background-color: ${hexToRgbaString(black, 0.3)};
      `;
    }

    return css`
      background-color: transparent;
    `;
  };

  const menuDropdownOptions: NvDropdownOption[] = [];

  if (isCurrentUserEnrolled && (!offering.isClosed || currentUserEnrollement.canWithdraw || currentUserEnrollement.canAudit)) {
    if (currentUserEnrollement.canAudit) {
      if (currentUserEnrollement.isAuditing) {
        menuDropdownOptions.push({
          type: 'custom',
          customItem: (
            <ClickableContainer
              aria-label={t.OFFERINGS.CARD.REVERSE_AUDITING_STATUS()}
              className='bs4-dropdown-item d-flex text-nowrap px-3 py-1'
              onClick={() => dispatch(
                reenrollCourse(offering.catalogId),
              )}
            >
              <span>
                {t.OFFERINGS.CARD.REVERSE_AUDITING_STATUS()}
              </span>
            </ClickableContainer>
          ),
        });
      } else {
        menuDropdownOptions.push({
          type: 'custom',
          customItem: (
            <ClickableContainer
              aria-label={t.OFFERINGS.CARD.AUDIT()}
              pendo-tag-name={config.pendo.learnerDashboard.audit}
              className='bs4-dropdown-item d-flex text-nowrap px-3 py-1'
              onClick={() => {
                const modalInstance = $scope.ConfirmationOverlays.openConfirmationModal(
                  'dashboard/templates/modal-audit-confirmation.html',
                );

                modalInstance.result.then(() => {
                  dispatch(
                    auditCourse(offering.catalogId),
                  ).then(() => dispatch(updateEnrollment({
                    id: currentUserEnrollement.id,
                    patch: {
                      isAuditing: true,
                    },
                  })));
                });
              }}
            >
              <span>
                {t.OFFERINGS.CARD.AUDIT()}
              </span>
            </ClickableContainer>
          ),
        });
      }
    }

    if (!currentUserEnrollement.paid && offering.paymentAccepted) {
      menuDropdownOptions.push({
        type: 'link',
        text: t.OFFERINGS.CARD.MAKE_PAYMENT(),
        link: RailsRoutes.newTransactionPath(offering.catalogId),
      });
    }

    if (currentUserEnrollement.canWithdraw) {
      menuDropdownOptions.push({
        type: 'custom',
        customItem: (
          <ClickableContainer
            aria-label={t.OFFERINGS.CARD.WITHDRAW()}
            pendo-tag-name={config.pendo.learnerDashboard.unenroll}
            className='bs4-dropdown-item d-flex text-nowrap px-3 py-1'
            onClick={() => {
              const modalInstance = $scope.ConfirmationOverlays.openConfirmationModal('dashboard/templates/modal-withdraw-confirmation.html');

              modalInstance.result.then(() => dispatch(
                withdrawCourse(offering.catalogId),
              ).then(() => {
                dispatch(removeUserEnrollment({
                  userId: currentUser.id,
                  enrollmentId: currentUserEnrollement.id,
                }));

                CurrentUserManager.user.removeEnrollment(currentUserEnrollement.id);
              }));
            }}
          >
            <span>
              {t.OFFERINGS.CARD.WITHDRAW()}
            </span>
          </ClickableContainer>
        ),
      });
    }
  }

  const shouldRenderDropdown = !!menuDropdownOptions.length && !offering.isJourney;

  const courseThumbnailSrc = offering.thumbnail === '/assets/brand/temp.png'
    ? ''
    : offering.thumbnail;

  const journeyThumbnailSrc = offering.headerBackground;

  const offeringThumbnailSrc = offering.isJourney ? journeyThumbnailSrc : courseThumbnailSrc;

  const triangularBadgeColor = isComplete ? POSITION_INDICATOR_COLOR_COMPLETED : POSITION_INDICATOR_COLOR;

  const skillTags = offering?.skillTags || [];

  const styles = css`
    position: relative;

    ${offering.isProgram && css`
      &:before {
        top: 35px;
        content: "";
        position: absolute;
        border-style: solid;
        right: -${quarterSpacing}px;
        border-width: ${quarterSpacing / 2}px;
        border-color: ${gray5} transparent transparent ${gray5};
      }
    `}

    .position-indicator-container {
      top: 0;
      left: 0;
      position: absolute;

      .position-indicator {
        border-style: solid;
        border-width: ${standardSpacing}px;
        border-color: ${triangularBadgeColor} transparent transparent ${triangularBadgeColor};
      }

      .number-container {
        top: 0;
        left: 0;
        color: #fff;
        position: absolute;
        width: ${standardSpacing}px;
        height: ${standardSpacing}px;
      }
    }

    .card-program-badge {
      color: #fff;
      height: 25px;
      position: absolute;
      top: ${halfSpacing}px;
      right: -${quarterSpacing}px;
      font-weight: ${boldFontWeight};
      background-color: ${brandingColorToUse};
      box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.1);
      padding: ${quarterSpacing}px ${halfSpacing}px;
    }

    .offering-card-header {
      position: relative;
      border-bottom: ${quarterSpacing}px solid ${brandingColorToUse};
      ${offeringThumbnailSrc ? css`
        background: url(${offeringThumbnailSrc}) center / cover no-repeat;
      ` : css`
        background-color: ${brandingColorToUse};
      `};

      .offering-backdrop-layer {
        ${getDefaultBackdropStyles()};
        transition: background-color ${DEFAULT_TRANSITION_DURATION}ms;

        .journey-badge {
          bottom: 0;
          left: 50%;
          z-index: 1;
          position: absolute;
          transform: translate(-50%, 60%);

          > div {
            ${(offering.nameFontColor || currentInstitution.brandBarFontColor) && `
              color: ${offering.nameFontColor || currentInstitution.brandBarFontColor}
            `}
          }
        }
      }
    }

    .menu {
      position: absolute;
      bottom: ${threeQuartersSpacing}px;
      right: ${threeQuartersSpacing / 2}px;

      .menu-icon {
        color: ${gray2};
        transform: rotate(90deg);
      }
    }

    &:hover {
      ${activeCardHeaderBackdropStyles};
    }

    ${isFocused && activeCardHeaderBackdropStyles};

    .offering-card-content {
      overflow: hidden;
      position: relative;
      background-color: #fff;
      padding: ${halfSpacing}px ${threeQuartersSpacing}px;

      .card-program-name {
        color: ${gray2};
        text-align: center;
        font-family: ${openSans};
        margin-bottom: ${halfSpacing}px;
        font-size: ${textSmallFontSize}px;
        font-weight: ${semiBoldFontWeight};
        line-height: ${textSmallLineHeight}px;
      }

      .offering-name-container {
        display: flex;
        align-items: center;
        margin-bottom: ${halfSpacing}px;
        height: ${numberOfOfferingNameLines * OFFERING_NAME_LINE_HEIGHT - 15}px;

        .offering-name {
          width: 100%;
          color: ${black};
          text-align: center;
          font-weight: ${normalFontWeight};
          font-family: ${openSansCondensed};
          font-size: ${textExtraLargeFontSize}px;
          line-height: ${OFFERING_NAME_LINE_HEIGHT}px;
        }
      }

      .badge-container {
        display: flex;
        position: relative;
        justify-content: center;
        height: ${largeSpacing + 15}px;
        margin-bottom: ${halfSpacing}px;
        .view-more-wrapper {
          line-height: 12px;
          .separator {
            display: inline-block;
            width: ${quarterSpacing}px;
            height: ${quarterSpacing}px;
            background-color: ${gray8};
            border-radius: 50%;
            margin: 3px ${quarterSpacing}px;
            vertical-align: middle;
          }
        }
      }

      .bottom-container {
        position: relative;
        height: ${threeQuartersSpacing}px;
        padding-right: ${shouldRenderDropdown ? 16 : 0}px;

        .journey-date, .not-journey-date, .learners-enrolled-container {
          text-align: center;
          font-family: ${openSans};
          font-size: ${textSmallFontSize}px;
          line-height: ${textSmallLineHeight}px;
        }

        .journey-date {
          color: ${gray2};
        }

        .not-journey-date {
          color: ${isUpcoming ? gray2 : gray1};
        }

        .access-remaining {
          font-weight: ${semiBoldFontWeight};
        }

        .learners-enrolled-container {
          .learners-enrolled {
            max-width: 100%;
            align-items: center;
            display: inline-flex;

            .icon {
              color: ${warning};
              display: inline-block;
              margin-right: ${quarterSpacing}px;
            }

            & > span {
              flex: 1;
            }
          }
        }
      }
    }
  `;

  const overlayStyles = css`
    padding: ${halfSpacing}px ${standardSpacing}px;
  `;

  const handleCardClick = () => {
    // Adding the journeyCardInfo to persist data of the learning journey card info to add styles to the journey badge
    if (journeyCardInfo?.catalogId) {
      dispatch(updateVisitedJourney(journeyCardInfo));
    }

    if (!isCurrentUserEnrolled) {
      navigateToFlyer();
    } else if (isInProgress) {
      navigateToCurrentLecture();
    } else {
      navigateToHome();
    }
  };

  const handleCardBlur = () => setIsFocused(false);

  const handleCardFocus = () => setIsFocused(true);

  const getCurrentLayer = (currentLayer: number) => (isFocused ? 1 : currentLayer);

  const renderHeader = (isMouseIn) => {
    const getHoverOverlay = () => {
      const isEntitlementBasedEnrollment = offering.typeOfRegistration === CourseRegistrationType.OPEN_BASED_ON_ENTITLEMENTS;

      const canBeOpenInJourney = isEntitlementBasedEnrollment
        || offering.typeOfRegistration === CourseRegistrationType.CLOSED_ENROLLMENT;

      const isOpenToAll = offering.typeOfRegistration === CourseRegistrationType.FREE_ENROLLMENT_IN_INSTITUTION
        || offering.typeOfRegistration === CourseRegistrationType.FREE_ENROLLMENT;

      const shouldShowOfferedToOverlay = (isEntitlementBasedEnrollment && offering.matchingEntitlements) || isOpenToAll;

      const isExceptionForUnmetRequirementOverlay = shouldShowOfferedToOverlay;

      if (isEnrolledToParent
          && isInJourney
          && locked
          && !isCurrentUserEnrolled
          && !isExceptionForUnmetRequirementOverlay
          && (canBeOpenInJourney ? !offering.openInJourney : true)
      ) {
        return <UnmetRequirementOverlay css={overlayStyles} key='unmet-requirement-overlay' />;
      }

      if (isComplete) {
        return null;
      }

      const unmetEntitlementRequirement = isEntitlementBasedEnrollment && !offering.matchingEntitlements;

      const isNotOpenInJourney = ((isInJourney && isEnrolledToParent && canBeOpenInJourney) ? !offering.openInJourney : true);

      const notOpenForSelfEnrollment = (
        (unmetEntitlementRequirement && isNotOpenInJourney)
          || ((offering.typeOfRegistration === CourseRegistrationType.CLOSED_ENROLLMENT) && isNotOpenInJourney)
          || (offering.closeDate && isOfferingCloseDatePast)
          || (offering.registrationCloseDate && isOfferingRegistrationCloseDatePast)
      );

      if (!isCurrentUserEnrolled) {
        // eslint-disable-next-line no-nested-ternary
        if (isOfferingIndependent
          ? notOpenForSelfEnrollment
          : notOpenForSelfEnrollment && ((isInJourney && isEnrolledToParent) ? !isExceptionForUnmetRequirementOverlay : true)
        ) {
          return <UnmetRequirementOverlay css={overlayStyles} key='unmet-requirement-overlay' />;
        }

        if (shouldShowOfferedToOverlay && ((isInJourney && isEnrolledToParent && canBeOpenInJourney) ? !offering.openInJourney : true)) {
          return <OfferedToOverlay css={overlayStyles} key='offered-to-overlay' />;
        }
      }

      return null;
    };

    const hoverOverlay = getHoverOverlay();

    const getDefaultOverlay = () => {
      if (locked) {
        return <LockedOverlay css={overlayStyles} key='locked-overlay' />;
      }

      if (isComplete) {
        return <CompletedStatusOverlay key='completed-status-overlay' />;
      }

      return <EmptyOverlay key='default-empty-overlay' />;
    };

    const accessibleCurrent = getCurrentLayer(isMouseIn ? 1 : 0);
    const current = hoverOverlay ? accessibleCurrent : 0;

    return (
      <React.Fragment>
        <LayerTransitioner current={current}>
          {getDefaultOverlay()}
          {hoverOverlay || <EmptyOverlay key='hover-empty-overlay' />}
        </LayerTransitioner>
        {offering.isJourney && (
          <div className='journey-badge'>
            <Badge
              color={white}
              iconName='path'
              backgroundColor={brandingColorToUse}
            >
              {t.OFFERINGS.CARD.BADGE.LEARNING_JOURNEY()}
            </Badge>
          </div>
        )}
      </React.Fragment>
    );
  };

  const renderBadge = (isMouseIn: boolean) => {
    const getDefaultButton = () => {
      const renderEnrolledBadge = () => {
        if (isComplete) {
          return (
            <Badge
              color='#fff'
              iconName='check'
              key='complete-button'
              backgroundColor={success}
            >
              {offering.isJourney ? t.OFFERINGS.CARD.JOURNEY_COMPLETED() : t.OFFERINGS.CARD.COMPLETED()}
            </Badge>
          );
        }

        if (isIncomplete) {
          if (offering.automatedCompletionsEnabled ? true : offering.hasBeenGrantedManualCompletion) {
            return (
              <Badge key='incomplete-button'>
                {t.OFFERINGS.CARD.INCOMPLETE()}
              </Badge>
            );
          }

          return null;
        }

        if (offering.isJourney && requiredCoursesCompleted) {
          return (
            <Badge
              color={primary}
              borderColor={primary}
              key='journey-progress-badge'
              backgroundColor='transparent'
            >
              {t.OFFERINGS.CARD.JOURNEY_PROGRESS(requiredCoursesCompleted, completionStats?.required)}
            </Badge>
          );
        }

        if (!offering.isProgram) {
          if (isInProgress) {
            return (
              <Badge
                color='#fff'
                key='in-progress-badge'
                backgroundColor={primary}
              >
                {t.OFFERINGS.CARD.IN_PROGRESS()}
              </Badge>
            );
          }

          return (
            <Badge
              key='enrolled-badge'
              Icon={EnrolledAvatarIcon}
            >
              {t.OFFERINGS.CARD.ENROLLED()}
            </Badge>
          );
        }

        return null;
      };

      if (isCurrentUserEnrolled) {
        return (
          <BadgeLayer key='enrolled-badge-layer'>
            {renderEnrolledBadge()}
          </BadgeLayer>
        );
      }

      return (showSkillTags && !isMouseIn && skillTags.length > 0)
        ? renderSkillTags()
        : <EmptyLayer key='not-enrolled-empty-layer' />;
    };

    const getHoverButton = () => {
      const renderGoHomeButton = () => {
        const handleGoHomeClick = () => navigateToHome();

        const getCTAText = () => {
          if (offering.isProgram) {
            return t.OFFERINGS.CARD.VIEW_DETAILS();
          }

          if (!isCurrentUserEnrolled) {
            return t.OFFERINGS.CARD.LEARN_MORE();
          }

          return t.OFFERINGS.CARD.VIEW_DETAILS();
        };

        return (
          <BadgeLayer key='go-home-button' style={{ backgroundColor: white }}>
            <Badge
              color='#fff'
              backgroundColor={primary}
            >
              {getCTAText()}
            </Badge>
          </BadgeLayer>
        );
      };

      const getEnrolledCTA = () => {
        if (offering.isJourney || offering.isProgram) {
          return renderGoHomeButton();
        }

        if (!regularIsComplete && !isIncomplete) {
          return (
            <BadgeLayer key='enrolled-button'>
              <Badge
                color='#fff'
                backgroundColor={primary}
              >
                {isInProgress ? t.OFFERINGS.CARD.RESUME_LEARNING() : t.OFFERINGS.CARD.START_LEARNING()}
              </Badge>
            </BadgeLayer>
          );
        }

        // Returning empty layer since when completed current layer is always
        // the same as the default one.
        return <EmptyLayer key='enrolled-empty-layer' />;
      };

      if (isCurrentUserEnrolled) {
        if (isComplete || isIncomplete) {
          return null;
        }

        return getEnrolledCTA();
      }

      return renderGoHomeButton();
    };

    const renderSkillTags = () => (
      <div className='skill-tags text-center'>
        <div className='text-center bold gray-1 text-xs'>
          {t.INSTITUTIONS.SKILL_TAGS.SKILL_VIEW_FOR_LEARNERS.SKILLS_RELATED()}:
        </div>
        <NvViewMore
          maxRows={2}
          rowHeight={14}
          gap={1}
          collapser={ViewMoreCollapser.CUSTOM}
          contract
          customViewMore={() => (
            <span className='skill-tag text-xs gray-3'>
              {t.INSTITUTIONS.SKILL_TAGS.SKILL_VIEW_FOR_LEARNERS.VIEW_SKILLS_IN_FLYER()}
            </span>
          )}
          type={ViewMoreBehavior.CUSTOM}
        >
          {sortSkillTags(_.clone(skillTags)).map((skillTag, index) => (
            <span
              key={skillTag.id}
              className='skill-tag-name text-xs'
            >
              {skillTag.name}
              {index !== offering.skillTags.length - 1 && (
              <div className='separator' />
              )}
            </span>
          ))}
        </NvViewMore>
      </div>
    );

    const defaultButton = getDefaultButton();
    const hoverButton = getHoverButton();
    const accessibleCurrent = getCurrentLayer(isMouseIn ? 1 : 0);
    const current = hoverButton ? accessibleCurrent : 0;

    return (
      <LayerTransitioner current={current} childrenKey={hoverButton?.key || ''}>
        {defaultButton}
        {hoverButton || <EmptyLayer />}
      </LayerTransitioner>
    );
  };

  const renderNotJourneyDate = () => {
    const course = offering;

    const dateText = (() => {
      if (course.isSelfPaced) {
        if (course.enrollmentLimitInDays) {
          if (!userCourse) {
            return t.DASHBOARD.COMPLETE_IN(course.enrollmentLimitInDays);
          }

          const { accessCloseDate: courseAccessCloseDate } = userCourse;
          const remainingAccessInDays = courseAccessCloseDate && moment(courseAccessCloseDate) > currentMoment ? Math.ceil(moment(courseAccessCloseDate).diff(currentMoment, 'days', true)) : null;
          // Course has limited number of days remaining
          if (remainingAccessInDays) {
            return t.DASHBOARD.DAYS_REMAINING(remainingAccessInDays.toString());
          }
        }

        // Self paced course with close date
        if (!course.enrollmentLimitInDays && course.closeDate) {
          return t.DASHBOARD.OPEN_UNTIL(moment(course.closeDate).format('MOMENT.MONTH_DAY_YEAR'));
        }

        if (course.officialReleaseDate) {
          return `${t.DASHBOARD.STARTED()} ${releaseDateMoment.format('MOMENT.MONTH_DAY_YEAR')}`;
        }
      } else {
        // Not released Course
        if (isUpcoming) {
          // Starting today
          if (releaseDateMoment.diff(currentMoment, 'hours') > 0 && releaseDateMoment.diff(currentMoment, 'hours') < 24) {
            return t.DASHBOARD.STARTING_TODAY();
          }

          // Starting this week
          if (releaseDateMoment.diff(currentMoment, 'days') > 0 && releaseDateMoment.diff(currentMoment, 'days') < 7) {
            const countdownToRelease = releaseDateMoment.diff(currentMoment, 'days');
            return t.DASHBOARD.STARTING_THIS_WEEK(countdownToRelease);
          }

          // Starting after a week
          if (course.officialReleaseDate && course.endDate) {
            return `${releaseDateMoment.format('MOMENT.MONTH_DAY_YEAR')} - ${moment(course.endDate).format('MOMENT.MONTH_DAY_YEAR')}`;
          }

          if (course.officialReleaseDate) {
            return t.DASHBOARD.COURSE_STARTING(releaseDateMoment.format('MOMENT.MONTH_DAY_YEAR'));
          }

          if (course.endDate) {
            return t.DASHBOARD.COURSE_ENDING(moment(course.endDate).format('MOMENT.MONTH_DAY_YEAR'));
          }
        }

        // Released Course
        if (course.officialReleaseDate && course.endDate) {
          return `${releaseDateMoment.format('MOMENT.MONTH_DAY_YEAR')} - ${moment(course.endDate).format('MOMENT.MONTH_DAY_YEAR')}`;
        }

        if (course.officialReleaseDate) {
          return `${t.DASHBOARD.STARTED()} ${releaseDateMoment.format('MOMENT.MONTH_DAY_YEAR')}`;
        }

        if (course.endDate) {
          return `${t.DASHBOARD.STARTED()} ${moment(course.endDate).format('MOMENT.MONTH_DAY_YEAR')}`;
        }
      }

      return '';
    })();

    return (
      <div className='not-journey-date ellipsis'>{dateText}</div>
    );
  };

  const renderJourneyDate = () => {
    const formatToDate = (date: string) => moment(date).format('MM/DD/YYYY');

    const remainingCourseDays = moment(offering.closeDate).diff(moment(), 'days');

    switch (true) {
      case (
        isCurrentUserEnrolled
          && (offering.isSelfPaced && (
            !!offering.enrollmentLimitInDays
              && (selfPacedDaysRemaining > 0 && selfPacedDaysRemaining <= 7)
          ))
      ):
        return (
          <div className='journey-date access-remaining text-danger ellipsis'>
            {t.OFFERINGS.CARD.X_DAYS_ACCESS_REMAINING(offering.isSelfPaced ? selfPacedDaysRemaining : remainingCourseDays)}
          </div>
        );
      case (
        !isCurrentUserEnrolled
          && offering.isSelfPaced
          && !!offering.enrollmentLimitInDays
      ):
        return (
          <div className='journey-date ellipsis'>
            {t.OFFERINGS.CARD.COMPLETE_IN(offering.enrollmentLimitInDays)}
          </div>
        );
      case (!!offering.closeDate && isOfferingCloseDatePast):
        return (
          <div className='journey-date ellipsis'>
            {t.LEARNING_JOURNEYS.DETAILS.COURSES.DATES.CLOSED_ON()}
            {' '}
            {formatToDate(offering.closeDate)}
          </div>
        );
      case (!!offering.releaseDate && !!offering.closeDate):
        return (
          <div className='journey-date ellipsis'>
            {formatToDate(offering.releaseDate)}
            {' - '}
            {formatToDate(offering.closeDate)}
          </div>
        );
      case (!!offering.releaseDate && isOfferingReleaseDatePast):
        return (
          <div className='journey-date ellipsis'>
            {t.LEARNING_JOURNEYS.DETAILS.COURSES.DATES.RELEASED_ON()}
            {' '}
            {formatToDate(offering.releaseDate)}
          </div>
        );
      case (!!offering.releaseDate || !!offering.closeDate):
        return (
          <div className='journey-date ellipsis'>
            {t.LEARNING_JOURNEYS.DETAILS.COURSES.DATES[offering.releaseDate ? 'RELEASE_ON' : 'CLOSURE_ON']()}
            {' '}
            {formatToDate(offering.releaseDate || offering.closeDate)}
          </div>
        );
      default:
        return <div className='journey-date ellipsis' />;
    }
  };

  const renderedDate = offering.isJourney ? renderJourneyDate() : renderNotJourneyDate();

  const renderNotEnrolledBottomSection = (isMouseIn) => (offering.numEnrolled ? (
    <LayerTransitioner
      current={getCurrentLayer(isMouseIn ? 1 : 0)}
    >
      {React.cloneElement(renderedDate, {
        key: 'date',
      })}
      <div className='learners-enrolled-container' key='learners-enrolled'>
        <div className='learners-enrolled'>
          <NvIcon icon='community' size='smallest' />
          <span className='ellipsis'>
            {t.OFFERINGS.CARD.ENROLLED_COUNT(offering.numEnrolled)}
          </span>
        </div>
      </div>
    </LayerTransitioner>
  ) : renderedDate);

  return (
    <OfferingContext.Provider
      value={{
        ...offering,
        inEnrolledJourneys,
      }}
    >
      <BaseCard
        ref={ref}
        css={styles}
        style={style}
        className={className}
        onBlur={handleCardBlur}
        onClick={handleCardClick}
        onFocus={handleCardFocus}
        renderHeader={renderHeader}
        headerClassName='offering-card-header'
        contentClassName='offering-card-content'
        backdropLayerClassName='offering-backdrop-layer'
        isFocusable={isFocusable}
        renderExtraContent={() => (
          <React.Fragment>
            {position !== undefined && (
              <div className='position-indicator-container'>
                <div className='position-indicator' />
                <div className='label d-flex justify-content-center align-items-center number-container'>{position}</div>
              </div>
            )}
            {offering.isProgram && (
              <div className='text-small card-program-badge'>
                {t.OFFERINGS.CARD.PROGRAM_OFFERING_NAME()}
              </div>
            )}
            {shouldRenderDropdown && (
              <div className='menu' onClick={(e) => e.stopPropagation()}>
                <NvDropdown
                  drop='down'
                  items={menuDropdownOptions}
                  container={contextContainer}
                  align={NvDropdownAlign.RIGHT}
                  buttonStyle={NvDropdownButtonStyle.ICON}
                  iconClass='icon-smallest icon-in-progress menu-icon'
                  isFocusable={isFocusable}
                  altLabel={t.OFFERINGS.CARD.MORE_OPTIONS()}
                />
              </div>
            )}
          </React.Fragment>
        )}
        renderContent={(isMouseIn) => (
          <React.Fragment>
            {isInProgram && (
              <div className='card-program-name ellipsis'>
                {parent.name}
              </div>
            )}
            <div className='offering-name-container'>
              <NvTooltip
                placement='top'
                text={offering.name}
                enabled={offering.name.length > 30}
              >
                <h3 className='offering-name'>
                  <Truncate
                    ellipsis='...'
                    lines={isInProgram ? 2 : 3}
                  >
                    {` ${offering.name} `}
                  </Truncate>
                </h3>
              </NvTooltip>
            </div>
            <div className='badge-container'>
              {renderBadge(isMouseIn)}
            </div>
            <div className='bottom-container'>
              {isCurrentUserEnrolled ? (
                renderedDate
              ) : renderNotEnrolledBottomSection(isMouseIn)}
            </div>
          </React.Fragment>
        )}
        data-qa={dataQa}
        data-qa-id={dataQaId}
        aria-label={t.OFFERINGS.CARD.ENTER_COURSE(offering.name)}
        role='navigation'
      />
    </OfferingContext.Provider>
  );
});

type BadgeProps = {
  color?: string,
  children: string,
  iconName?: string,
  borderColor?: string,
  backgroundColor?: string,
  Icon?: React.ComponentType<React.SVGProps<SVGElement>>,
};

const Badge = React.forwardRef<HTMLDivElement, BadgeProps>((props, ref) => {
  const {
    Icon,
    children,
    iconName,
    color = black,
    backgroundColor = gray6,
    borderColor = backgroundColor,
  } = props;

  const styles = css`
    display: flex;
    max-width: 100%;
    color: ${color};
    align-items: center;
    font-family: ${openSans};
    height: ${largeSpacing}px;
    border: 1px solid ${borderColor};
    font-size: ${textSmallFontSize}px;
    font-weight: ${semiBoldFontWeight};
    line-height: ${headerLineHeight}px;
    border-radius: ${largeSpacing / 2}px;
    padding: 0 ${threeQuartersSpacing}px;
    background-color: ${backgroundColor};

    svg, .icon {
      margin-right: ${halfSpacing}px;
    }

    span {
      flex: 1;
    }
  `;

  return (
    <div ref={ref} css={styles}>
      {iconName && (
        <NvIcon
          icon={iconName}
          size='xss-smallest'
        />
      )}
      {Icon && <Icon width={16} height={16} />}
      <span className='ellipsis'>{children}</span>
    </div>
  );
});

const BadgeLayer = React.forwardRef<HTMLDivElement, EmptyLayerProps>((props, ref) => (
  <EmptyLayer
    {...props}
    ref={ref}
    className='d-flex justify-content-center align-items-center'
  />
));

type EmptyLayerProps = React.ComponentProps<'div'>;

const EmptyLayer = React.forwardRef<HTMLDivElement, EmptyLayerProps>((props, ref) => <div {...props} ref={ref} />);

export default OfferingCard;
