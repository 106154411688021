import * as Sentry from '@sentry/browser';
import { setLanguage } from 'react-translate';
import { PermissionTypes } from 'institutions/services/roles-service';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import store from 'redux/store';
import { removeCourseBookmarks } from 'redux/actions/bookmarks';
import { getUserCourse } from 'redux/actions/courses';

/* @ngInject */
export default function CurrentUserManager(
  $q,
  UserModel,
  CourseModel,
  UserCourseModel,
  UsersResources,
  CoursesResource,
  PusherManager,
  CurrentPermissionsManager,
  CourseRolesManager,
  moment,
  amMoment,
  _,
  InstitutionsManager,
  $location,
  $translate,
  $window,
  config,
  $document,
  $state,
  RolesService,
) {
  // unfortunately there doesn't seem to be an obvious list, best to look at node_modules
  const MOMENT_LANGAUGE_MAPPING = {
    en_US: 'en',
    es_MX: 'es',
    es_ES: 'es',
    fr_FR: 'fr',
    pt_PT: 'pt',
    pt_BR: 'pt-br',
    zh_CN: 'zh-cn',
    zh_TW: 'zh-tw',
    ja_JP: 'ja',
    ko_KP: 'ko',
    ru_RU: 'ru',
    de_DE: 'de',
    ar_SA: 'ar',
    he_IL: 'he',
    pl_PL: 'pl',
    it_IT: 'it',
    fr_CA: 'fr-ca',
    nl_NL: 'nl',
    ro_RO: 'ro',
    sv_SE: 'sv',
    tr_TR: 'tr',
    id_ID: 'id',
    th_TH: 'th',
  };

  // https://developers.google.com/recaptcha/docs/language
  const RECAPTCHA_LANGUAGE_MAPPING = {
    en_US: 'en',
    es_MX: 'es',
    es_ES: 'es',
    fr_FR: 'fr',
    pt_PT: 'pt-PT',
    pt_BR: 'pt-BR',
    zh_CN: 'zh-CN',
    zh_TW: 'zh-TW',
    ja_JP: 'ja',
    ko_KP: 'ko',
    ru_RU: 'ru',
    de_DE: 'de',
    ar_SA: 'ar',
    he_IL: 'iw',
    pl_PL: 'pl',
    it_IT: 'it',
    fr_CA: 'fr-CA',
    nl_NL: 'nl',
    ro_RO: 'ro',
    sv_SE: 'sv',
    tr_TR: 'tr',
    id_ID: 'id',
    th_TH: 'th',
  };

  const _this = {
    user: {},
    courses: [],
    releasedCourses: [],
    coursesHash: {},
    coursesHashByCatalogId: {},
    courseIdToUserCourseHash: {},
    currentUserCourse: null,
    requestCurrentUserPromise: null,
    currentInstitution: null,
    lastCopyToOffering: null,
    isInMentorDashboard: false,
    cachedApprovalComments: [],

    reinitialize,
    isAdmin,
    isAdminInCourse,
    isCourseAdminInAtLeastOneCourse,
    isSupervisor,
    isNovoedAdmin,
    signIn,
    signUp,
    signOut,
    setNewUser,
    requestCurrentUser,
    requestCourse,
    getCurrentUserCourse,
    resetCurrentUserCourse,
    updateOrigamiLanguage,
    addUserCourse,
    refreshUserCourse,
    addCourse,
    removeCourse,
    getInstitutionAdmin,
    isOrgAdminInAnyInstitution,
    setCurrentInstitution,
    courseIsInCurrentInstitution,
    updatePlatformLanguage,
    hasLoggedIn,
    hasEnrolledInCourse,
    removeUnreadTrackable,
    markDowntimeAlertAsRead,
    initializePendo,
    setEmailPreferenceCatalogId,
    getEmailPreferenceCatalogId,
    setLastCopyToOffering,
    isAdminForCurrentCourse,
    isInstructorForCurrentCourse,
    isTeachingAssistantForCurrentCourse,
    isCourseBuilderForCurrentCourse,
    getCurrentCourse,
    getCurrentUserCourseByUrl,
    requestAndUpdateCourse,
    removeUserEnrollment,
    setCourses,
    sortCourses,
    setUserCourses,
    updateEmail,
    setApprovalCommentTranslations,
  };

  let emailPreferencesCatalogId;
  let previousUserChannelIdentifier;
  let previousLanguage;

  function reinitialize() {
    return requestCurrentUser();
  }

  function hasLoggedIn() {
    return !_.isEmpty(_this.user);
  }

  function hasEnrolledInCourse(course) {
    if (course && _this.courseIdToUserCourseHash[course.id]) {
      return true;
    }
    return false;
  }

  /** Whether this user has all available permissions for the current course */
  function isAdmin() {
    return CurrentPermissionsManager.hasCourseAdminPermissions();
  }

  /** Whether this user has all available permissions for the current state course
    * If the user opened a team workspace / nv-submission modal of a different course other than set currentCourseManager
    * Check admin roles instead of using CurrentPermissionsManager
  */
  function isAdminForCurrentCourse() {
    if ($state.params?.catalogId && CurrentPermissionsManager?.course?.catalogId !== $state.params?.catalogId) {
      const { roles } = _this.getCurrentUserCourseByUrl() ?? {};
      return CurrentPermissionsManager.hasCourseManagerPermissions() || (roles && RolesService.hasAdminPermissions(roles.permission));
    }
    return CurrentPermissionsManager.hasCourseAdminPermissions();
  }

  /** Whether this user has instructor permissions for the given state course
   * If the user opened a team workspace / nv-submission modal of a different course other than set currentCourseManager
   * Check permission here instead of using CurrentPermissionsManager
  */
  function isInstructorForCurrentCourse() {
    if ($state.params?.catalogId && CurrentPermissionsManager?.course?.catalogId !== $state.params?.catalogId) {
      const { roles } = _this.getCurrentUserCourseByUrl() ?? {};
      return CurrentPermissionsManager.hasCourseManagerPermissions() || (roles && RolesService.hasPermission(roles.permission, PermissionTypes.INSTRUCTOR));
    }
    return CurrentPermissionsManager.isInstructor();
  }

  /** Whether this user has teaching assistant permissions for the given state course
   * If the user opened a team workspace / nv-submission modal of a different course other than set currentCourseManager
   * Check permission here instead of using CurrentPermissionsManager
   */
  function isTeachingAssistantForCurrentCourse() {
    if ($state.params?.catalogId && CurrentPermissionsManager?.course?.catalogId !== $state.params?.catalogId) {
      const { roles } = _this.getCurrentUserCourseByUrl() ?? {};
      return CurrentPermissionsManager.hasCourseManagerPermissions() || (roles && RolesService.hasPermission(roles.permission, PermissionTypes.TEACHER_ASSISTANT));
    }
    return CurrentPermissionsManager.isTeachingAssistant();
  }

  /** Whether this user has course builder permissions for the given state course
   * If the user opened a team workspace / nv-submission modal of a different course other than set currentCourseManager
   * Check permission here instead of using CurrentPermissionsManager
  */
  function isCourseBuilderForCurrentCourse() {
    if ($state.params?.catalogId && CurrentPermissionsManager?.course?.catalogId !== $state.params?.catalogId) {
      const { roles } = _this.getCurrentUserCourseByUrl() ?? {};
      return CurrentPermissionsManager.hasCourseManagerPermissions() || (roles && RolesService.hasPermission(roles.permission, PermissionTypes.COURSE_BUILDER));
    }
    return CurrentPermissionsManager.isCourseBuilder();
  }

  /** Whether this user has all available permissions for a given course */
  function isAdminInCourse(course, roles) {
    if (_this.user.admin || (InstitutionsManager.institution?.isInstitutionalAdmin)) {
      return true;
    }

    let isCourseAdmin = false;
    if (course.userCourse?.roles) {
      // Non-admin 'Learner' roles have permission == 0; all other values indicates a course admin of some variety
      isCourseAdmin = course.userCourse.roles.permission > 0;
    } else if (roles && RolesService.hasAdminPermissions(roles.permission)) {
      isCourseAdmin = true;
    }

    return isCourseAdmin;
  }

  // Check if user has course admin permissions in at least one course/program/journey
  function isCourseAdminInAtLeastOneCourse() {
    return _this.user.hasCourseAdmin;
  }

  function isNovoedAdmin() {
    return _this.user.admin;
  }

  function isSupervisor() {
    return _this.user?.mentoringInfo?.isMentor;
  }

  function signIn(options) {
    const requestBody = {
      user: {
        email: _this.user.email,
        password: _this.user.password,
      },
    };

    if (options) {
      _.extend(requestBody, options);
    }

    _this.requestCurrentUserPromise = UsersResources.signIn(
      requestBody,
      (resource) => {
        _this.setNewUser(resource.result.user);
        return resource;
      },
    ).$promise;

    return _this.requestCurrentUserPromise;
  }

  function signUp(params, recaptchaResponse) {
    return UsersResources.signUp(
      {
        recaptchaResponse,
        user: {
          firstName: _this.user.firstName,
          lastName: _this.user.lastName,
          email: _this.user.email,
          password: _this.user.password,
        },
        catalogId: params.catalogId,
        mentor: params.mentor === 'true',
      },
      (resource) => {
        _this.setNewUser(resource.result);
      },
    ).$promise;
  }

  function signOut() {
    _this.user = {};
    return UsersResources.signOut().$promise;
  }

  function setUserCourses(data) {
    _this.courseIdToUserCourseHash = {};
    _.each(data.enrollments, addUserCourse);
  }

  function addUserCourse(uc) {
    const userCourse = new UserCourseModel(uc);
    _this.courseIdToUserCourseHash[userCourse.course.id] = userCourse;
  }

  function refreshUserCourse(courseId) {
    return store.dispatch(getUserCourse({ courseId }))
      .then((response) => {
        const enrollment = response?.payload;
        if (enrollment) {
          addUserCourse(enrollment);
          addCourse(new CourseModel(enrollment.course), true, enrollment.roles);
        }
      });
  }

  function setCourses(data) {
    _this.courses = [];
    _this.releasedCourses = [];
    /**
     * Content management collections don't available in enrollments, so keeping
     * already added collections in coursesHash and coursesHashByCatalogId.
     * Otherwise, this manager doesn't have the collection data even if accessed
     * from the collection areas.
     */
    _this.coursesHash = _.pick(_this.coursesHash, ((course) => course.isContentManagementCollection));
    _this.coursesHashByCatalogId = _.pick(_this.coursesHashByCatalogId, ((course) => course.isContentManagementCollection));
    _this.programsHash = {};

    _.each(data.enrollments, (enrollment) => {
      addCourse(new CourseModel(enrollment.course), true, enrollment.roles);
    });

    _.each(_this.courses, (course) => {
      if (course.isInProgram()) {
        // check each program against enrollments to make sure the user is enrolled in the program
        course.parentPrograms = _.filter(_.compact(course.parentPrograms), (program) => !!_this.coursesHash[program.id]);

        // take the remaining programs that user is enrolled in and populate programs hash
        _.each(course.parentPrograms, (program) => {
          if (!_this.programsHash[program.id]) {
            _this.programsHash[program.id] = program;
          }

          if (!_this.programsHash[program.id].coursesInProgram) {
            _this.programsHash[program.id].coursesInProgram = [];
          }

          if (!_.findWhere(_this.programsHash[program.id].coursesInProgram, { id: course.id })) {
            _this.programsHash[program.id].coursesInProgram.push(course);
          }
        });
      }
    });
  }

  function sortCourses() {
    _this.courses.sort(CourseModel.sortByUrgencyToAccess);
    _this.releasedCourses.sort(CourseModel.sortByUrgencyToAccess);
  }

  function setLastCopyToOffering(data) {
    _this.lastCopyToOffering = data.lastCopyToOffering;
  }

  function removeUserEnrollment(enrollmentId) {
    const [removedEnrollment] = _this.user.removeEnrollment(enrollmentId);
    _this.rawUserData.enrollments = _this.user.enrollments;
    store.dispatch(removeCourseBookmarks(removedEnrollment.course.catalogId));
  }

  function setNewUser(data) {
    // A clone of the user data stored to be passed to redux
    // A deep clone is required because when we create Course Model instances, we only only do a shallow clone of the data. Any nested objects will reference the original object
    // This solution is used instead of using cloneDeepSerializable later because of the following scenario:
    // When a user has a program and a course in that program, the code in current-user-manager creates 2 instances of the course, one for the course, and one to be linked to the program.
    // However, the source course data used to instantiate the Course instances are the same, so both Course instances will point to the same object for property lectureName
    // This causes a problem for deepCloneSerializer because it sees the same instance of lectureName and drop the property in the subsequent courses
    const jsonData = data.toJSON?.() ?? data;
    _this.rawUserData = cloneDeep(jsonData);

    _this.user = new UserModel(data);
    _this.user.authActivityInProgress = false;
    CurrentPermissionsManager.setCurrentUser(_this.user);
    setCurrentInstitution(data);
    setUserCourses(data); // should be set before courses
    setCourses(data);
    sortCourses();
    initializePendo();
    setLastCopyToOffering(data);

    const identifier = _this.user.anonymizedIdentifier.substr(0, 10);
    if (previousUserChannelIdentifier) {
      PusherManager.removeUserChannel(previousUserChannelIdentifier);
    }
    PusherManager.setupUserChannel(identifier);
    previousUserChannelIdentifier = identifier;


    amMoment.changeTimezone(_this.user.timeZone);
    updateOrigamiLanguage(_this.user.platformLanguage, true);

    Sentry.configureScope((scope) => {
      // used to differentiate between users in Sentry, we currently do not track by IP because sentry has not been deemed a GDPR sub-processor which we should consider in the long run
      // TODO: needs to be done on the React side but not as straightforward
      scope.setUser({ id: identifier });
    });
  }

  function requestCurrentUser() {
    const deferred = $q.defer();

    UsersResources.currentUser({
      bust: new Date().getTime(),
      mentoring_info: 1,
      membership_info: 1,
    }).$promise.then((response) => {
      _this.setNewUser(response);
      deferred.resolve();
    }, (response) => {
      deferred.resolve();
    });

    _this.requestCurrentUserPromise = deferred.promise;

    return deferred.promise;
  }

  function updateOrigamiLanguage(language, reloadSiteIfFlippingRtl = false) {
    if (language !== previousLanguage) {
      const prevLangIsRtl = _.contains(config.rtlLanguages, previousLanguage);
      const currentLangIsRtl = _.contains(config.rtlLanguages, language);

      if (prevLangIsRtl !== currentLangIsRtl && reloadSiteIfFlippingRtl) {
        $window.location.reload();
      } else {
        previousLanguage = language;

        $document[0].documentElement.lang = language.replace('_', '-');

        moment.locale(MOMENT_LANGAUGE_MAPPING[language]);
        amMoment.changeLocale(MOMENT_LANGAUGE_MAPPING[language]);

        _this.recaptchaLang = RECAPTCHA_LANGUAGE_MAPPING[language];

        setLanguage(language);
        return $translate.use(language);
      }
    }
    return $q.when();
  }


  function requestCourse(catalogId) {
    return CoursesResource.get(
      { catalogId, serializer: 'list' },
      (resource) => {
        addCourse(new CourseModel(resource.result));
      },
    ).$promise;
  }

  /**
   * Fetch course and update course hashes.Won't set currentCourseManager
   * This shares the same code with currentCourseManager but it won't update currentCourseManager
   * this only update currentUserManager course hashes.
   * This is useful when we need to show L4 modals and L3.5 flyout panel of a course ( eg: Course A) where a different course is
   * loaded in currentCourseManager (Course B).
   * This function will fetch course full data and update the course hashes which is loaded from myaccount api on the initial load
   * Updated course data can be fetched from currentUserManager.getCurrentCourse()
   * @param {*} catalogId
   * @param {boolean} flushCache If this is true, flush the course hash for the current catalogId and update latest course full data from API
   * @param {*} retryCount For retrying if the first request fails
   */

  function requestAndUpdateCourse(catalogId, flushCache, retryCount) {
    // Tracks the # of attempts to re-request the course data in the event that it fails. 5 is an arbitrary # of
    // attempts chosen; isn't based on any hard data
    const currentRetry = !retryCount ? 5 : retryCount - 1;
    if (_this.requestCurrentCoursePromise && _this.currentRequestingCatalogId === catalogId) {
      return _this.requestCurrentCoursePromise;
    }
    /**
     * Return stored data if full course is loaded and flushCache is not required
     */
    if (!flushCache && _this.coursesHashByCatalogId[catalogId]?.fullCourseLoaded) {
      return $q.when(_this.coursesHashByCatalogId[catalogId]);
    }


    _this.currentRequestingCatalogId = catalogId;

    const deferred = $q.defer();

    CoursesResource.get({ catalogId }).$promise.then(
      (resource) => {
        const course = new CourseModel(resource.result);

        if (!course.institution) {
          if (InstitutionsManager?.institution) {
            course.institution = InstitutionsManager.instituion;
          }
        }

        course.inArchiveMode = course.inArchiveMode || (course.userCourse?.accessInArchiveMode);

        // This is used determine whether the full course data is loaded to the course hashes.
        // When course hashes are updated from myaccount api fullCourseLoaded
        // will be false and this will get set when full course data is loaded.
        course.fullCourseLoaded = true;

        // TODO: Remove this line after backend implementation
        course.isNextButtonEnabledOnLastPage = false;

        addCourse(course, true);

        deferred.resolve(course);
        _this.requestCurrentCoursePromise = null;

        return course;
      },
      (error) => {
        // Attempt requesting the course data again should the previous request fail and is not an intended rejection by backend
        if (currentRetry > 0 && (error.status < 400 || error.status >= 500)) {
          _this.requestCurrentCoursePromise = null;
          const newPromise = _this.requestAndUpdateCourse(catalogId, flushCache, currentRetry);
          deferred.resolve(newPromise);
          return;
        }

        // We reject our promise because we usually have a mechanism to redirect the user
        deferred.reject(error);
        _this.requestCurrentCoursePromise = null;

        // If we have an error that is not intended by the backend, inform Sentry
        if (error.status < 400 || error.status >= 500) {
          throw new Error(`Unable to retrieve course with catalogId = ${catalogId}; error status = ${error.status}`);
        }
      },
    );

    _this.requestCurrentCoursePromise = deferred.promise;
    return deferred.promise;
  }

  function resetCurrentUserCourse(data) {
    _this.currentUserCourse = data ? new UserCourseModel(data) : null;
  }

  function getCurrentUserCourse() {
    return _this.currentUserCourse;
  }

  /**
   * When opening team workspace on top of an existing lecture page of a different course
   * This is identified if a catalog Id is available in state object which is not identical to
   * the course set in currentUserCourse. In that case we will get the userCourseModal saved in courseIdToUserCourseHash
   * for that respective course instead of using default
   */
  function getCurrentUserCourseByUrl() {
    if ($state.params?.catalogId
      && _this.coursesHashByCatalogId[$state.params?.catalogId]) {
      return _this.courseIdToUserCourseHash[_this.coursesHashByCatalogId[$state.params?.catalogId].id];
    }
    // Fall back if for some reason course
    return _this.currentUserCourse;
  }

  /**
   * Get Current course based on state param
   * Needed because : Flyout panel can be opened on top of the current lecture page which can be a different course
   * So this function will fetch course modal for the current state
   */
  function getCurrentCourse() {
    if ($state.params?.catalogId) {
      return _this.coursesHashByCatalogId[$state.params?.catalogId];
    }
    return null;
  }

  function removeCourse(course) {
    const indexToRemove = null;

    _this.courses = _.without(_this.courses, _.findWhere(_this.courses, { id: course.id }));
    _this.releasedCourses = _.without(_this.releasedCourses, _.findWhere(_this.releasedCourses, { id: course.id }));

    if (course.institution?.host) {
      const hasOtherCoursesInInstitution = _.find(_this.user.institutions, (iteratee) => iteratee.host && iteratee.host === course.institution.host);

      if (!hasOtherCoursesInInstitution) {
        _this.user.institutions = _.without(_this.user.institutions, course.institution);
      }
    }

    delete _this.coursesHash[course.id];
    delete _this.coursesHashByCatalogId[course.catalogId];
  }

  function addCourse(course, overwrite, roles) {
    if (!_this.coursesHash[course.id] || overwrite) {
      const userCourse = _this.courseIdToUserCourseHash[course.id];

      const extras = {
        inArchiveMode: course.inArchiveMode || (userCourse?.accessInArchiveMode),
      };

      course.setExtras(extras);

      const arrayPosition = _this.courses.findIndex((c) => c.catalogId === course.catalogId);
      if (arrayPosition === -1) {
        _this.courses.push(course);
      }

      _this.coursesHash[course.id] = course;
      _this.coursesHashByCatalogId[course.catalogId] = course;

      if (course.released || _this.isAdminInCourse(course, roles)) {
        const arrayPositionReleased = _this.releasedCourses.findIndex((c) => c.catalogId === course.catalogId);
        /**
         * Updating releasedCourses will cause a re-render of lhs panel.
         * While opening the learner profile modal from the mentor dashboard
         * no need to update the LHS with the course. So only adding if update is
         * not from the mentor dashboard and the course is not already existing.
         */
        if (arrayPositionReleased === -1 && !_this.isInMentorDashboard && !course.isJourney) {
          _this.releasedCourses.push(course);
        }
      }
    }
  }

  function getInstitutionAdmin(institutionId) {
    const institution = _.find(_this.user.institutions, (institutionObj) => institutionObj.id === institutionId && institutionObj.isInstitutionalAdmin);

    const institutionAdmin = _.findWhere(_this.user.institutionAdmins, { institutionId });

    if (institution && institutionAdmin) {
      institution.lastViewedDashboardAt = institutionAdmin.lastViewedDashboardAt;
    }

    return institution;
  }

  function isOrgAdminInAnyInstitution() {
    if (!_this.user) {
      return false;
    }
    return _.filter(_this.user.institutions, (institution) => {
      if (institution.isInstitutionalAdmin) {
        return true;
      }

      return false;
    }).length > 0;
  }

  function setCurrentInstitution(data) {
    if (data.institution) {
      InstitutionsManager.setInstitution(merge({}, InstitutionsManager.institution, data.institution));
      _this.currentInstitution = InstitutionsManager.institution;
      CourseRolesManager.loadCourseRoles(InstitutionsManager.institution.id);
      CurrentPermissionsManager.setCurrentInstitution(InstitutionsManager.institution);
      InstitutionsManager.updateFavicon({ url: InstitutionsManager.institution.favicon });
    } else {
      const urlHost = $location.host();
      _.find(data.institutions, (institution) => {
        if (institution.host === urlHost) {
          if (!InstitutionsManager.institution || InstitutionsManager.institution.id !== institution.id) {
            InstitutionsManager.institution = institution;
          }
          _this.currentInstitution = InstitutionsManager.institution;

          if (InstitutionsManager.institution?.id) {
            CourseRolesManager.loadCourseRoles(InstitutionsManager.institution.id);
          }

          InstitutionsManager.updateFavicon({ url: institution.favicon });
          CurrentPermissionsManager.setCurrentInstitution(InstitutionsManager.institution);
          return true;
        }

        return undefined;
      });
    }
  }

  function courseIsInCurrentInstitution(courseId) {
    const currentUrlHost = $location.host();

    const enrollment = _this.coursesHash[courseId];

    if (enrollment) {
      return enrollment.institution.host === currentUrlHost;
    }
    return false;
  }

  function updatePlatformLanguage(selectedLanguage) {
    const prevLangIsRtl = _.contains(config.rtlLanguages, previousLanguage);
    const currentLangIsRtl = _.contains(config.rtlLanguages, selectedLanguage);

    const payload = {
      user: { platformLanguage: selectedLanguage },
    };

    _this.requestUpdatedLanguage = UsersResources.updatePlatformLanguage(payload).$promise.then((response) => {
      if (prevLangIsRtl !== currentLangIsRtl) {
        $window.location.reload();
      }

      return response;
    });

    return _this.requestUpdatedLanguage;
  }

  function removeUnreadTrackable(trackableId) {
    return UsersResources.markTrackableAsRead({ id: trackableId }).$promise.then(() => {
      _this.user.unreadLegalTrackables = _.reject(_this.user.unreadLegalTrackables, (trackable) => trackable.id === trackableId);
    });
  }

  function markDowntimeAlertAsRead() {
    return UsersResources.markDowntimeAlertAsRead().$promise.then(() => {
      _this.user.mustSeeDowntimeAlert = false;
    });
  }

  function initializePendo(course) {
    if (config.pendo.enabled === true) {
      $window.pendo.initialize({
        visitor: _.extend(
          _.extend({
            id: config.pendo.prefix ? `${config.pendo.prefix}-${_this.user.id}` : _this.user.id,
            platformLanguage: _this.user.platformLanguage,
            location: _this.user.displayLocation,
            isOrgAmin: _this.isOrgAdminInAnyInstitution(),
            onNovoEdTeam: _this.user.onNovoedTeam,
            email: _this.isOrgAdminInAnyInstitution() || (course && _this.isAdmin()) ? _this.user.email : null,
          }, course ? course.getPendoProperties() : { }),
          course ? CurrentPermissionsManager.getPendoProperties() : { },
        ),

        account: {
          id: $location.host(),
          name: InstitutionsManager.institution?.name,
          nickname: InstitutionsManager.institution?.nickname,
          parentNickname: InstitutionsManager.instituion?.parentNickname,
          approxUsed: InstitutionsManager.institution?.lcountCached,
          totalSeats: InstitutionsManager.institution?.lcountTotal,
          licenseStartDate: InstitutionsManager.institution?.currentPeriodStartedAt,
          licenseEndDate: InstitutionsManager.institution?.currentPeriodEndedAt,
          isPilot: InstitutionsManager.institution?.isPilot,
          industrySegment: InstitutionsManager.institution?.industrySegment,
          createdAt: InstitutionsManager.institution?.createdAt,
          offeringsAudience: InstitutionsManager.institution?.offeringsAudience,
          isTestorDemo: InstitutionsManager.institution?.testOrDemo,
          isActive: InstitutionsManager.institution?.active,
          isAppPromotionEnabled: InstitutionsManager.institution?.isAppPromotionEnabled,
        },
      });
    }
  }

  function setEmailPreferenceCatalogId(catalogId) {
    emailPreferencesCatalogId = catalogId;
  }

  function getEmailPreferenceCatalogId() {
    return emailPreferencesCatalogId;
  }

  function updateEmail(email) {
    _this.user.email = email;
    _this.user.hasSystemGeneratedAddress = false;
  }

  function setApprovalCommentTranslations(payload) {
    _this.cachedApprovalComments = payload;
  }

  return _this;
}
