import Truncate from 'react-truncate';
import { ReactComponent as MentoringProgramHeaderPicture } from 'styles/icons/mentoring-program-header-picture.svg';
import WorkspaceTitle from './workspace-title';
import moment from 'moment';

const HeaderMainContent = ({
  mentoringProgram,
  renderProgramDates,
  showBackMyMentees,
  currentUser,
  connectionUser,
  isParticipant,
}) => {
  const isMatchDateFuture = moment(
    mentoringProgram?.matchAnnouncementDate
  ).isAfter(moment());

  const isClosed = mentoringProgram?.endDate && moment().isAfter(moment(mentoringProgram.endDate));
  const isReleased = mentoringProgram?.releasedDate && moment().isAfter(moment(mentoringProgram.releasedDate));

  const showWorkspaceTitle = isParticipant && connectionUser && !isMatchDateFuture && !isClosed && isReleased;

  return (
    <div className="main">
      {!isParticipant && (
        <div className="d-flex align-items-end date-container text-body">
          {renderProgramDates()}
        </div>
      )}
      <div className="w-50">
        <Truncate lines={3} ellipsis="..." className="heading-1">
          {mentoringProgram?.name}
        </Truncate>
      </div>
      {!isParticipant && (
        <div className="w-50">
          <Truncate
            lines={3}
            ellipsis="..."
            className="text-large-body main-description"
          >
            {mentoringProgram?.description}
          </Truncate>
        </div>
      )}
      <MentoringProgramHeaderPicture
        width={432}
        height={266.4}
        className="mentoring-program-picture position-absolute"
      />
      {showWorkspaceTitle && (
        <WorkspaceTitle
          currentUser={currentUser}
          connectionUser={connectionUser}
          showGoBack={showBackMyMentees}
        />
      )}
    </div>
  );
};

export default HeaderMainContent;
